/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import Icon from '+containers/Dashboard/Shared/Icons';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { TPaymentPreferenceRequest } from '+types';
import { capitalizeRemovedash, getDate, getTime, logError } from '+utils';

import PreferenceDropdown from '../paymentPreferenceTabsWidgets/PreferenceDropdown';
import PreferenceStatus from '../paymentPreferenceTabsWidgets/PreferenceStatus';

import './preference-request-history.scss';

const limit = 5;

const api = new APIRequest();
export default function PreferenceRequestHistory({ history }: { history: TPaymentPreferenceRequest[] }) {
  const { id } = useParams<{ id: string }>();
  const { feedbackInit } = useFeedbackHandler();
  const [page, setPage] = useState(1);
  const lastApproved = useRef<number | null>(null);

  const { data, isLoading } = useQuery([id, page], () => api.getMerchantsPaymentPreferencesRequest({ merchantId: id, page, limit }), {
    onError: e => {
      logError(e);
      feedbackInit({
        message: 'There has been an error fetching merchant payment preference history',
        type: 'danger'
      });
    }
  });

  const pendingRequest = history[0]?.status === 'pending';
  if (history?.length === 1 && pendingRequest) {
    return <EmptyStateComponent heading="No data" message="History of preference requests will appear here." />;
  }

  const mappedData = ((pendingRequest && page === 1 ? data?.data?.slice(1) : data?.data) || history) as TPaymentPreferenceRequest[];
  const endOfPage = Math.ceil(Number(data?.paging?.total_items) / limit) === page;

  return (
    <section className="preference-request-history">
      <div className="preference-request-history__headers">
        <div>
          <h5 data-testid="preference-request-history-title">Preference Request History</h5>
          <p data-testid="preference-request-history-subtitle" className="subtitle">
            Here, you will find the history of previous requests and the actions taken on them.
          </p>
        </div>
      </div>

      {isLoading ? (
        <LoadingPlaceholder type="table" content={2} />
      ) : mappedData?.length ? (
        mappedData.map((pref, i) => {
          if (lastApproved.current === null) lastApproved.current = pref.status === 'approved' ? pref.id : null;

          return (
            <div data-testid={`preference-history-${i}`} className="preference-dropdown-wrapper" key={pref.id}>
              <PreferenceDropdown
                header=<div className="header-wrapper">
                  <div className="time-stamp">
                    <Icon name={pref.status === 'approved' ? 'checkRounded' : 'failed'} width={14} height={14} />{' '}
                    <span className="approved">{capitalizeRemovedash(pref.status)}</span>
                    <span>
                      {getDate(pref.updatedAt)} <span className="grey-text">{getTime(pref.updatedAt)}</span>
                    </span>
                  </div>

                  {pref.id === lastApproved.current && <span className="active-preference">Active</span>}
                </div>
              >
                <div className="pref-history-summary">
                  <div className="history-content-wrapper">
                    <div data-testid="overpayment-row" className="preference-dropdown__item">
                      <p data-testid="preference-dropdown-item-title">Overpayment</p>
                      <PreferenceStatus to={capitalizeRemovedash(pref.payment_events.overpayment)} />
                    </div>
                    <div data-testid="underpayment-row" className="preference-dropdown__item">
                      <p data-testid="preference-dropdown-item-title">Underpayment</p>
                      <PreferenceStatus to={capitalizeRemovedash(pref.payment_events.underpayment)} />
                    </div>
                    <div data-testid="date-submitted-row" className="preference-dropdown__item">
                      <p data-testid="preference-dropdown-item-title">Date Submitted</p>
                      <div data-testid="preference-history-date-submitted">
                        {getDate(pref.createdAt)} <span className="grey-text">{getTime(pref.createdAt)}</span>
                      </div>
                    </div>
                    {pref.comments && (
                      <div data-testid="decline-reason-row" className="preference-dropdown__item">
                        <p data-testid="preference-dropdown-item-title">Reason for Declining</p>
                        <strong data-testid="preference-history-decline-reason">{pref.comments}</strong>
                      </div>
                    )}
                  </div>
                  <div data-testid="attestation-row" className="history-attestation-wrapper">
                    <div className="attestation">
                      <div>
                        <Icon name="file" width={18} height={18} fill="#414F5F" style={{ marginRight: 8 }} /> Signed Attestation Letter
                      </div>
                      <a
                        href={pref.document_reference}
                        download="letter_of_attestation.pdf"
                        aria-label="download attestation doecument link"
                      >
                        Download <Icon name="download" width={18} height={18} style={{ marginLeft: 8 }} />
                      </a>
                    </div>
                  </div>
                </div>
              </PreferenceDropdown>
            </div>
          );
        })
      ) : (
        <EmptyStateComponent heading="No data" message="History of preference requests will appear here." />
      )}
      {data?.paging?.total_items > limit && (
        <div data-testid="preference-history-pagination" className="paginate-preference">
          <span data-testid="number-of-preference-history">({data?.paging?.total_items}) Preference Requests</span>
          <div className="paginate-preference__btn-wrapper">
            <button disabled={isLoading || page === 1} onClick={() => setPage(1)} aria-label="Go to first" className="btn" type="button">
              <Icon name="doubleCaretLeft" stroke="currentColor" width={8} height={8} />
            </button>
            <button
              disabled={isLoading || page === 1}
              onClick={() => setPage(data?.paging?.previous)}
              aria-label="Go to previous"
              className="btn"
              type="button"
            >
              <Icon name="caretLeft" stroke="currentColor" width={8} height={8} />
            </button>
            <button
              disabled={isLoading || endOfPage}
              onClick={() => setPage(data?.paging?.next)}
              aria-label="Go to next"
              className="btn"
              type="button"
            >
              <Icon name="caretRight" stroke="currentColor" width={8} height={8} />
            </button>
            <button
              aria-label="Go to last"
              className="btn"
              type="button"
              disabled={isLoading || endOfPage}
              onClick={() => setPage(Math.ceil(Number(data?.paging?.total_items) / limit))}
            >
              <Icon name="doubleCaretRight" stroke="currentColor" width={8} height={8} />
            </button>
          </div>
        </div>
      )}
    </section>
  );
}
