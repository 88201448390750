/* eslint-disable camelcase */
import React from 'react';
import { useQuery } from 'react-query';

import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import Copyable from '+shared/Copyable';
import Table from '+shared/Table';
import {
  APIDownload,
  capitalize,
  capitalizeRemovedash,
  filteredOutObjectProperty,
  formatAmount,
  getDate,
  getTime,
  isAllowed,
  logError,
  queriesParams,
  switchStatus
} from '+utils';

const apiRequest = new APIRequest();

const Reversals = () => {
  const userAccess = useSetUserAccess();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '10';
  const status = searchQuery.value.status || [];

  const sortingParams = {
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value, [queriesParams.limit, queriesParams.status, queriesParams.page])
  };

  const {
    data: resolvedData,
    refetch,
    isFetching
  } = useQuery(['PAYMENT_REVERSALS', page, limit, sortingParams], () => apiRequest.getReversals(page, limit, sortingParams), {
    keepPreviousData: true,
    onError: () => {
      feedbackInit({
        title: 'Reversals',
        message: 'There has been an error getting payment reversals',
        type: 'danger',
        action: {
          action: () => {
            refetch();
          },
          name: 'Try again'
        }
      });
    }
  });

  const data = resolvedData?.data || [];
  const paging = resolvedData?.paging || [];

  const exportPayouts = async (format: string, close: () => void, fieldsToExport: string[]) => {
    try {
      const res: Awaited<Blob> = await apiRequest.exportReversals(format, fieldsToExport, sortingParams);
      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `Reversals Report for ${getDate(Date.now())}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: (
          <>
            {' '}
            - Successfully exported <strong>{paging?.total_items} transactions.</strong>
          </>
        ),
        type: 'success'
      });
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your reversals file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const reversals = {
    className: ' --reversal-table',
    rowURL: isAllowed(userAccess, ['pay_in_details.view']) ? '/dashboard/payment-reversals' : '/dashboard/access-denied',
    rowKey: isAllowed(userAccess, ['pay_in_details.view']) ? 'reference' : '',
    emptyStateHeading: 'No records yet',
    emptyStateMessage: 'There are no payment reversals yet.',
    annotations: 'reversal(s)',
    fields: (iter: any) => ({
      data: {
        status: (
          <>
            <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
            <span>{capitalizeRemovedash(iter.status || 'N/A')}</span>
          </>
        ),
        payout_id: <span>{iter?.reference?.toUpperCase()}</span>,
        'Initial Pay-in Reference': <span className="trxn-id">{iter?.transaction_reference?.toUpperCase() || 'Not available'}</span>,
        merchant: <span className="merch-name">{capitalize(iter?.merchant || 'Not available')}</span>,
        'Date / Time': (
          <>
            <span>{getDate(iter.reversal_date)}</span>
            <span className="annotation">{getTime(iter.reversal_date)}</span>
          </>
        ),
        amount: (
          <>
            <span>{formatAmount(iter.refund_amount)}</span>
            <span className="annotation">{iter.currency}</span>
          </>
        )
      }
    })
  };

  const tableDataKeys: string[] = Object.keys(reversals.fields({}).data);

  return (
    <div className="row">
      <div className="col-sm-12 reversals__page">
        <Table
          className={reversals.className || ''}
          loading={isFetching}
          data={data}
          renderFields
          hasPagination
          tableHeadings={tableDataKeys}
          annotation={reversals.annotations}
          current={parseInt(page, 10)}
          rowKey={reversals.rowKey}
          rowURL={reversals.rowURL}
          pageSize={paging?.page_size || 0}
          totalItems={paging?.total_items || 0}
          limitAction={currentLimit => searchQuery.setQuery({ limit: String(currentLimit) })}
          actionFn={currentPage => searchQuery.setQuery({ page: String(currentPage) })}
          emptyStateHeading={reversals.emptyStateHeading || ''}
          tableWrapperClassName="table-responsive"
          emptyStateMessage={reversals.emptyStateMessage || ''}
          type="reversals"
          filterHasAdvancedFilter={false}
          filterQueryIDPlaceholder="Payout ID"
          filterTotalCount={paging?.total_items}
          filterShowExport={isAllowed(userAccess, ['payment_reversals.export'])}
          filterExportAction={exportPayouts}
        >
          {reversals.fields}
        </Table>
      </div>
    </div>
  );
};

export default Reversals;
