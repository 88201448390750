import React from 'react';

import { useReducerState, useSetUserAccess } from '+hooks';
import { CurrencyType, modalState, productCategoriesType, ProductConfigType } from '+types';
import { availableProductCategories, formatProductConfigStatus, isAllowed, productMapping, switchTrxnMessage } from '+utils';

import MerchantCurrencyModal from '../../MerchantsTable/components/MerchantCurrencyModal';
import ProductCategories from '../components/ProductCategories';

import './index.scss';

type ProductsPropsType<T> = {
  config: T;
  type: productCategoriesType;
  currency: CurrencyType;
  merchantId: string;
  merchantsStatus: boolean;
};

const Products = ({ config, type, currency, merchantId, merchantsStatus }: ProductsPropsType<ProductConfigType>) => {
  const userAccess = useSetUserAccess();
  const [state, setState] = useReducerState<{ activeModal: modalState }>({
    activeModal: null
  });

  return (
    <div className="payins-container">
      <div className="payins-container__first">
        <div>
          <span>
            <h4>{availableProductCategories[type]}</h4>
            <span
              className="status"
              style={{
                color: switchTrxnMessage[formatProductConfigStatus(String(!merchantsStatus ? false : config?.enabled))]?.color,
                backgroundColor:
                  switchTrxnMessage[formatProductConfigStatus(String(!merchantsStatus ? false : config?.enabled))]?.backgroundColor
              }}
            >
              {switchTrxnMessage[formatProductConfigStatus(String(!merchantsStatus ? false : config?.enabled))]?.name}
            </span>
          </span>
          <p>{`Here you can find the ${availableProductCategories[type]} products for this merchant. You can modify their limits and payment channels configuration.`}</p>
        </div>

        <div>
          {merchantsStatus && (
            <>
              <p>See KYB </p>
              {isAllowed(userAccess, ['transaction_config_details.update']) && (
                <button
                  type="button"
                  className="btn btn-secondary --enable-btn"
                  onClick={() => {
                    setState({ activeModal: config?.enabled ? 'disable-product' : 'enable-product' });
                  }}
                  title={`${config?.enabled ? 'Disable' : 'Enable'} ${availableProductCategories[type]} for this merchant`}
                >
                  {`${config?.enabled ? 'Disable' : 'Enable'} ${availableProductCategories[type]} for this merchant`}
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <ProductCategories config={config} type={type} currency={currency} merchantId={merchantId} merchantsStatus={merchantsStatus} />
      {state.activeModal && (
        <MerchantCurrencyModal
          activeModal={state.activeModal}
          setActiveModal={() => setState({ activeModal: null })}
          currency={currency}
          merchantId={merchantId}
          productType={productMapping[type]}
          hideButton
        />
      )}
    </div>
  );
};

export default Products;
