/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-children-prop */
import TierIcon from '+assets/img/dashboard/tier.svg';
import VBAIcon from '+assets/img/dashboard/vba-icon.svg';
import Copyable from '+containers/Dashboard/Shared/Copyable';
import Icon from '+containers/Dashboard/Shared/Icons';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import Modal, { IModalProps } from '+shared/Modal';
import SecondaryDetails from '+shared/SecondaryDetails';
import Table from '+shared/Table';
import { capitalize, capitalizeRemovedash, formatAmount, getDate, getTime, history, switchStatus } from '+utils';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { TManageAccountModal } from '../AccountHoldersDetails/TypesAccountHolders';
import { accountStatusBanner, TBankAccountDetails } from '../data';
import ManageAccountModal from './ManageAccountModal';
import '../index.scss';

type TVbaTransactions = { [key in 'created_at' | 'currency' | 'amount' | 'status']: string } & {
  [key in 'payer_bank_account' | 'payment']: Record<string, string>;
};

const api = new APIRequest();

const reasonFeedbackContent = (description: string) => {
  const optionIsNotOther = description.includes('_');
  return (
    <div className="reason-modal">
      <div className="title"> {optionIsNotOther ? capitalizeRemovedash(description) : description}</div>
    </div>
  );
};

export default function VirtualBankAccountDetails() {
  const { id } = useParams() as { id: string };
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const page = Number(searchQuery.value.page) || 1;
  const limit = Number(searchQuery.value.limit) || 10;
  const [buttonState, setButtonState] = useState(false);
  const [seeMore, setSeeMore] = useState(false);

  const [modal, setModal] = useState({
    modalType: null,
    modalInfo: {} as IModalProps
  } as TManageAccountModal);

  const { data: detailsData, isLoading: isFetchingDetails } = useQuery(
    `${id?.toUpperCase()}_DETAILS`,
    () => api.getVirtualBankAccount(id),
    {
      refetchOnMount: 'always',
      onError: () => {
        feedbackInit({
          message: "There has been an error fetching this account's information",
          type: 'danger'
        });
      }
    }
  );

  const { data: vbaTrxns, isFetching } = useQuery(
    [`${id?.toUpperCase()}_TRANSACTIONS`, page, limit],
    () => api.getVirtualBankAccountTransactions(id, page, limit),
    {
      refetchOnMount: 'always',
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          message: "There has been an error getting this account's transactions",
          type: 'danger'
        });
      }
    }
  );

  const paging = vbaTrxns?.paging || {};
  const transactions = vbaTrxns?.data || [];
  const data = (detailsData?.data || {}) as TBankAccountDetails;
  const isNGN = data.currency === 'NGN';

  const trxLimits =
    !isNGN &&
    (() => {
      const trxLimit = data.transaction_limit?.individual || data.transaction_limit?.corporate;
      const tiers = data.tier === 1 ? trxLimit?.tier_one : trxLimit?.tier_two;
      return {
        Email: data.email || 'Not Available',
        'Max. Funding Limit (Per Transaction)': tiers?.single ? `${tiers?.single} ${data?.currency}` : 'Not Available',
        'Max. Funding Limit (Daily)': tiers?.daily ? `${tiers?.daily} ${data?.currency}` : 'Not Available',
        'Max. Funding Limit (Monthly)': tiers?.monthly ? `${tiers?.monthly} ${data?.currency}` : 'Not Available',
        'Date Created': `${getDate(data?.created_at)}`
      };
    })();

  const moreDetailsOnTheLeft = {
    ...(data?.currency === 'USD' && {
      'Fedwire Memo': data?.payment_schemes.fedwire ? (
        <Copyable
          text={`${data?.payment_schemes.fedwire?.memo.slice(0, 4)}*** `}
          textToCopy={data?.payment_schemes.fedwire?.memo}
          showText
          buttonClassName="copy-icon"
        />
      ) : (
        'Not Available'
      )
    }),
    'Account Status': (
      <>
        <span className={`status-pill smaller status-pill-smaller ${switchStatus(data.account_status)}`} />{' '}
        {capitalize(data.account_status || 'Not Available')}
      </>
    ),
    ...(!isNGN && {
      'Account Type': data?.account_type || 'Not Available',
      'Account Tier': data?.tier ? `Tier ${data?.tier}` : 'Not Available',
      Currency: data?.currency || 'Not Available'
    })
  };

  const vbaDetails = [
    {
      'Account Number': data.account_number || 'Not Available',
      'Account Name': data.account_name || 'Not Available',
      'Bank Name': `${capitalizeRemovedash(data?.bank_name || 'Not Available')}`,
      ...(data?.currency === 'USD' && {
        'Bank Address': data?.payment_schemes.fedwire?.bank_name || 'Not Available',
        'Fedwire Routing Code': data?.payment_schemes.fedwire ? (
          <Copyable
            text={`${data?.payment_schemes.fedwire?.routing_code.slice(0, 4)}*** `}
            textToCopy={data?.payment_schemes.fedwire?.routing_code}
            showText
            buttonClassName="copy-icon"
          />
        ) : (
          'Not Available'
        )
      }),
      ...(data?.currency === 'USD' ? seeMore && moreDetailsOnTheLeft : moreDetailsOnTheLeft)
    },
    {
      ...(data?.currency === 'NGN' && {
        Currency: data?.currency || 'Not Available'
      }),
      ...(data?.currency === 'USD' && {
        'ACH Routing Code': data?.payment_schemes.ach ? (
          <Copyable
            text={`${data?.payment_schemes.ach?.routing_code.slice(0, 4)}*** `}
            textToCopy={data?.payment_schemes.ach?.routing_code}
            showText
            buttonClassName="copy-icon"
          />
        ) : (
          'Not Available'
        ),
        'ACH Memo': data?.payment_schemes.ach ? (
          <Copyable
            text={`${data?.payment_schemes.ach?.memo.slice(0, 4)}*** `}
            textToCopy={data?.payment_schemes.ach?.memo}
            showText
            buttonClassName="copy-icon"
          />
        ) : (
          'Not Available'
        ),
        'Swift Code': data?.payment_schemes.swift ? (
          <Copyable
            text={`${data?.payment_schemes.swift?.swift_code.slice(0, 4)}*** `}
            textToCopy={data?.payment_schemes.swift?.swift_code}
            showText
            buttonClassName="copy-icon"
          />
        ) : (
          'Not Available'
        ),
        'Swift Memo': data?.payment_schemes.swift ? (
          <Copyable
            text={`${data?.payment_schemes.swift?.memo.slice(0, 4)}*** `}
            textToCopy={data?.payment_schemes.swift?.memo}
            showText
            buttonClassName="copy-icon"
          />
        ) : (
          'Not Available'
        )
      }),
      'Account Reference': data?.unique_id ? (
        <Copyable text={`${data?.unique_id} `} showText buttonClassName="copy-icon" />
      ) : (
        'Not Available'
      ),
      ...(data?.currency === 'USD'
        ? seeMore && trxLimits
        : !isNGN
        ? trxLimits
        : { Email: data.customer?.email || 'Not Available', 'Date Created': `${getDate(data?.created_at)}` })
    }
  ];

  const vbaTransactions = {
    className: '--vba-acc-trx',
    rowURL: '/dashboard/pay-ins',
    rowKey: 'reference',
    emptyStateHeading: 'No transactions yet',
    emptyStateMessage: 'There are no transactions for this account yet.',
    annotations: 'transaction(s)',
    fields: (iter: TVbaTransactions) => ({
      data: {
        status: (
          <>
            <span className={`status-pill smaller ${switchStatus(iter.status)}`} />
            <span>
              {capitalize(iter?.status || '')}
              {iter?.status?.includes('flagged') && <Icon name="warningTriangle" width={16} height={16} style={{ marginLeft: 5 }} />}
            </span>
          </>
        ),
        'Transaction ID': (
          <span style={{ color: '#007bff', fontWeight: '500', textTransform: 'uppercase' }}>{iter.payment?.reference}</span>
        ),
        transaction_date: (
          <>
            <span>{getDate(iter.created_at)}</span>
            <span className="annotation" style={{ marginLeft: '5px' }}>
              {getTime(iter.created_at)}
            </span>
          </>
        ),

        amount: (
          <span>
            <span style={{ fontWeight: '600' }}>{formatAmount(iter.amount)} </span>
            {data?.currency || 'NGN'}
          </span>
        )
      }
    })
  };

  const openReasonFeedbackModal = () => {
    setModal({
      modalType: 'reasonFeedback',
      modalInfo: {
        heading: `Reason for ${detailsData.data.account_status === 'suspended' ? 'Suspension' : 'Deactivation'}`,
        content: reasonFeedbackContent(detailsData.data.status_reason),
        hideSecondButton: true,
        firstButtonText: 'Dismiss'
      } as unknown as IModalProps
    });
  };

  const tableDataKeys = Object.keys(vbaTransactions.fields({} as TVbaTransactions).data);

  const ForeignAccNumNotPending = data?.currency !== 'NGN' && data?.account_status !== 'pending';

  return (
    <div className="content-box">
      <div className="row mb-3">
        {!isFetchingDetails && (
          <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
            <i className="os-icon os-icon-arrow-left7" />
            <span style={{ fontSize: '13px', fontWeight: '500' }}>Go Back</span>
          </button>
        )}
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <div className="content-details-box">
              <div className="content-details-head --content-details">
                {!isFetchingDetails && (
                  <>
                    <div className="content-details-title">
                      <div>
                        <img src={VBAIcon} alt="" />
                      </div>
                      <div className="cd-title ml-3">
                        <h3 className="cd-maintitle" aria-label="Account Number">
                          {data?.account_number}
                        </h3>
                        <p className="cd-subtitle">{capitalizeRemovedash(data?.bank_name || 'Not Available')}</p>
                      </div>
                      {data?.currency !== 'NGN' && (
                        <div className="cd-tier">
                          <img src={TierIcon} alt="" />
                          <span>Tier {data?.tier}</span>
                        </div>
                      )}
                    </div>
                    {ForeignAccNumNotPending && (
                      <div className="cd-action">
                        <ManageAccountModal
                          setModal={setModal}
                          setButtonState={setButtonState}
                          accountReference={id}
                          accountStatus={data.account_status}
                          accountHolderReference={data?.account_holder?.reference}
                          trxId={id}
                          modalType={modal.modalType}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="virtual-account-summary">
                {isFetchingDetails ? (
                  <LoadingPlaceholder type="text" content={4} />
                ) : (
                  <>
                    <SecondaryDetails title="Account Summary" data={vbaDetails} hideLine leftSpacing="" />
                    {data.currency === 'USD' && (
                      <button onClick={() => setSeeMore(!seeMore)} type="button" className="view-more-btn">
                        <span>{seeMore ? 'Hide Details' : 'See more Details'} </span>{' '}
                        <Icon name="caretRight" style={{ transform: `rotateZ(${seeMore ? '90deg' : '0'})`, marginLeft: 6 }} />
                      </button>
                    )}
                  </>
                )}
                {accountStatusBanner({
                  type: 'number',
                  status: data.account_status,
                  reason: data.status_reason,
                  onClick: openReasonFeedbackModal
                })}
              </div>

              <Table
                header={`(${paging.total_items || 0}) Transactions`}
                className={vbaTransactions.className || ''}
                loading={isFetching}
                data={transactions}
                renderFields
                hasPagination
                tableHeadings={tableDataKeys}
                annotation={vbaTransactions.annotations}
                current={page}
                limitAction={(c: number | string) => searchQuery.setQuery({ limit: String(c) })}
                rowKey={vbaTransactions.rowKey}
                rowURL={vbaTransactions.rowURL}
                pageSize={paging?.page_size || 0}
                totalItems={paging?.total_items || 0}
                actionFn={(current) => searchQuery.setQuery({ page: String(current) })}
                emptyStateHeading={vbaTransactions.emptyStateHeading || ''}
                tableWrapperClassName="table-responsive"
                emptyStateMessage={vbaTransactions.emptyStateMessage || ''}
                hasFilter={false}
              >
                {vbaTransactions.fields}
              </Table>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={modal.modalType !== null}
        close={() => setModal({ modalType: null, modalInfo: {} as IModalProps })}
        heading={modal.modalInfo?.heading}
        description={modal.modalInfo?.description}
        content={modal.modalInfo?.content}
        size={modal.modalInfo?.size || 'md'}
        secondButtonText={modal.modalInfo?.secondButtonText}
        hideSecondButton={modal.modalInfo?.hideSecondButton}
        secondButtonAction={() => modal.modalInfo?.secondButtonAction?.()}
        secondButtonDisable={!buttonState}
        firstButtonText={modal.modalInfo?.firstButtonText}
        secondButtonActionIsTerminal={modal.modalInfo?.secondButtonActionIsTerminal}
        completedHeading={modal.modalInfo?.completedHeading}
        completedDescription={modal.modalInfo?.completedDescription}
        completedModalSize={modal.modalInfo?.completedModalSize}
        showButtons={modal.modalInfo?.showButtons}
        secondButtonColor={modal.modalInfo?.secondButtonColor}
        secondaryCompletedModal
      />
    </div>
  );
}
