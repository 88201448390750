/* eslint-disable no-nested-ternary */
import React from 'react';

import { ITableData, Nullable } from '+types';
import { SettlementType } from '+types/settlements';
import { capitalize, capitalizeFirst, capitalizeRemovedash, daysfromToday, formatAmount, getDate, switchStatus } from '+utils';

type summaryElement = {
  label: string;
  value: number | string;
  description: string | React.ReactNode;
};

export const switchTabData = (activeTab: string, activeCurrency: string): ITableData<SettlementType> | string => {
  return {
    className: '--settlement-table',
    rowURL: '/dashboard/settlements',
    rowKey: 'reference',
    type: 'settlements',
    emptyStateHeading: 'No transactions found',
    emptyStateMessage: 'It seems there are no issuing merchants cards yet.',
    annotations: 'transaction(s)',
    fields: (iter: SettlementType) => {
      return {
        data: {
          settlement_id: (
            <>
              <span className={`status-pill smaller ${switchStatus(activeTab)}`} />
              <span className="trxn-id">{iter?.reference?.toUpperCase()}</span>
            </>
          ),
          merchant: <span className="merch-name">{capitalize(iter.account?.name || 'Merchant')}</span>,
          method: (
            <span>{`${
              iter.category || iter.payment_method
                ? capitalize(
                    iter.payment_method === 'card'
                      ? `${iter.payment_method} Payment`
                      : capitalizeRemovedash(iter.payment_method || iter.category)
                  )
                : 'Not available'
            }`}</span>
          ),
          date_created: <span>{iter.createdAt ? getDate(iter.createdAt) : 'N/A'}</span>,
          [activeTab === 'settled' ? 'Date Settled' : 'Expected Date']: (
            <span>
              {activeTab === 'settled'
                ? iter.processed_at
                  ? getDate(iter.processed_at)
                  : 'N/A'
                : iter.expected_settlement_date
                ? capitalizeFirst(daysfromToday(iter.expected_settlement_date))
                : 'N/A'}
            </span>
          ),
          transaction_amount: (
            <>
              <span>
                <strong>{formatAmount(iter.transactions_amount)}</strong>
              </span>
              <span className="annotation">{activeCurrency}</span>
            </>
          ),
          [activeTab !== 'settled' ? 'To Settle' : 'Settled']: (
            <>
              <span>
                <strong>{activeTab !== 'settled' ? formatAmount(iter.amount || 0) : formatAmount(iter.amount_settled || 0)}</strong>
              </span>
              <span className="annotation">{activeCurrency}</span>
            </>
          )
        }
      };
    }
  };
};

export const summaryInfo = (
  activeTab: string,
  activeCurrency: string,
  summary: Record<string, string | number>
): Nullable<summaryElement> => {
  switch (activeTab) {
    case 'pending':
      return {
        label: `Total Pending Settlements (${activeCurrency})`,
        value: `${formatAmount(summary?.totalPendingSettlements || 0)}`,
        description: 'Settlement batches awaiting approval.'
      };
    case 'ready':
      return {
        label: `Ready for Approval (${activeCurrency})`,
        value: `${formatAmount(summary?.totalReadySettlements || 0)}`,
        description: 'Ready settlement batches awaiting approval.'
      };
    case 'approved':
      return {
        label: `Total Approved Settlement (${activeCurrency})`,
        value: `${formatAmount(summary?.totalApprovedSettlements || 0)}`,
        description: 'Approved batches awaiting final settlement.'
      };
    case 'settled':
      return {
        label: `Total Completed Settlements (${activeCurrency})`,
        value: `${formatAmount(summary?.totalProcessedSettlements || 0)}`,
        description: 'All completed merchant settlements.'
      };
    default:
      return null;
  }
};
