import React from 'react';

import Icon from '+shared/Icons';

import './index.scss';

const tierData = {
  1: { name: 'Test Mode', style: '--test', fill: '#f32345' },
  2: { name: 'Base Tier', style: '--base', fill: '#414F5F' },
  3: { name: 'TIER 1', style: '--one', fill: '#24B314' },
  4: { name: 'TIER 2', style: '--two', fill: '#FFB447' },
  5: { name: 'TIER 3', style: '--three', fill: '#2376F3' },
  6: { name: 'TIER 4', style: '--four', fill: '#6474FF' }
} as const;

type TierType = keyof typeof tierData;

const TierLevel = ({ tier }: { tier: number }) => {
  return (
    <div className={`tier-level ${tierData[tier as TierType].style}`}>
      <Icon name="medal" fill={tierData[tier as TierType].fill} />
      <p className="tier-name">{tierData[tier as TierType].name}</p>
    </div>
  );
};

export default TierLevel;
