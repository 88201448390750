import { EntityType, PermissionTableType } from '+types';

export const permissions: PermissionTableType[] = [
  {
    name: 'Analytics',
    key: 'dashboard'
  },
  {
    name: 'Merchants',
    key: 'merchants'
  },
  {
    name: 'Virtual Accounts',
    key: 'virtual_accounts'
  },
  {
    name: 'Pay-ins',
    key: 'pay-ins'
  },
  {
    name: 'Payouts',
    key: 'payouts'
  },
  {
    name: 'Reversals',
    key: 'payment_reversals'
  },
  {
    name: 'Settlements',
    key: 'settlements'
  },
  {
    name: 'Settlements Payouts',
    key: 'settlement_payouts'
  },
  {
    name: 'Webhooks',
    key: 'webhooks'
  },
  {
    name: 'Audit Logs',
    key: 'audit_logs'
  },
  {
    name: 'Global Merchant Settings',
    key: 'global_settings'
  },
  {
    name: 'Users',
    key: 'user'
  },
  {
    name: 'Roles',
    key: 'role'
  },
  {
    name: 'Chargebacks',
    key: 'chargebacks'
  },
  {
    name: 'Product Config',
    key: 'transaction_settings'
  },
  {
    name: 'Refunds',
    key: 'refunds'
  },
  {
    name: 'Card Issuance',
    key: 'card_issuance'
  },
  {
    name: 'Reports',
    key: 'reports'
  },
  {
    name: 'Partner Funding',
    key: 'partner_funding'
  },
  {
    name: 'Bank Transfer Settings Requests',
    key: 'bank_transfer_settings_requests'
  },
  {
    name: 'Identity',
    key: 'identity'
  }
];

export const filterPermissionOption = permissions.reduce((acc, current) => {
  (acc as Record<EntityType, string>)[current.key] = current.name;
  return acc;
}, {});
