/* eslint-disable consistent-return */
import React from 'react';

import { capitalizeAllCharacters } from '+utils';

import AvgMinus from '+assets/img/dashboard/avg-risk-icon.svg';
import ArrowDown from '+assets/img/dashboard/risk-arrow-down.svg';
import ArrowUp from '+assets/img/dashboard/risk-arrow-up.svg';
import Minus from '+assets/img/dashboard/risk-subtract.svg';

import './index.scss';

const riskLevelImg = {
  low: ArrowDown,
  high: ArrowUp,
  mid: Minus,
  avg: AvgMinus
};

const newName = {
  medium: 'mid',
  high: 'high',
  low: 'low',
  above: 'avg'
} as const;

type riskLevelPropType = {
  riskLevel: keyof typeof newName;
};

const formatRiskLevel = (riskLevel: string) => {
  if (!riskLevel) return;
  return newName[riskLevel.split('_')[0] as keyof typeof newName];
};

const RiskIndicator = ({ riskLevel }: riskLevelPropType) => {
  const initialStatus = formatRiskLevel(riskLevel);
  const status = initialStatus === 'avg' ? 'above avg' : initialStatus;
  return (
    <span className={`risk-label --${formatRiskLevel(riskLevel?.toLowerCase())}`}>
      <img src={riskLevelImg[formatRiskLevel(riskLevel?.toLowerCase()) as keyof typeof riskLevelImg]} alt="risk level" />
      <span className="text">{capitalizeAllCharacters(status || 'N/A')}</span>
    </span>
  );
};

export default RiskIndicator;
