/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Modal from '+shared/Modal';
import ReactSelectDropdown from '+containers/Dashboard/Shared/ReactSelectDropdown';
import { CustomDateRangeCalendar } from '+containers/Dashboard/Shared/CustomDateRangeCalendar';
import {
  IAllDateTimeProps,
  IDateRange,
  ReportViewType,
  ReportDateTimeOptionsType,
  ReportFormDataType,
  IGenerateReportModalProps
} from '+types';
import { availableCurrency, logError } from '+utils';
import Icon from '+containers/Dashboard/Shared/Icons';
import './index.scss';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const reportProcessorToShowChannel: string[] = ['wema'];
export const reportProcessorNameOptions = [
  { label: 'Sterling Bank', value: 'sterling' },
  { label: 'Wema Bank', value: 'wema' }
];
export const channelOptions = [
  { label: 'Korapay', value: 'KORA' },
  { label: 'Awe MFB', value: 'AWE' }
];
export const modalViewsOptions: { [k in ReportViewType]: ReportViewType } = {
  datetime: 'datetime',
  generate: 'generate',
  loader: 'loader',
  success: 'success',
  error: 'error'
};
const currencyOptions = Object.entries(availableCurrency).map((currency) => {
  return {
    label: currency[1],
    value: currency[0],
    isDisabled: !['NGN'].includes(currency[0])
  };
});
const GenerateReportModal: React.FC<IGenerateReportModalProps> = ({
  visible,
  closeModal,
  generateReportMutation,
  generateReportSuccessResponse,
  isGenerateReporError
}) => {
  const [modalView, setModalView] = useState<ReportViewType>(modalViewsOptions.generate);
  const [showChannelField, setShowChannelField] = useState<boolean>(false);
  const [dateTimeOption, setDateTimeOption] = useState<ReportDateTimeOptionsType[]>([]);
  const [selectedDate, setSelectedDate] = useState<IDateRange>({ startDate: '', endDate: '' });
  const [formData, setFormData] = useState<ReportFormDataType>({
    currency: '',
    processor: '',
    channel: ''
  });

  useEffect(() => {
    if (generateReportSuccessResponse) {
      setModalView(modalViewsOptions.success);
    }
  }, [generateReportSuccessResponse]);

  useEffect(() => {
    if (isGenerateReporError) {
      setModalView(modalViewsOptions.error);
    }
  }, [isGenerateReporError]);

  const onProcessorNameChange = (value: string) => {
    if (reportProcessorToShowChannel.includes(value)) {
      setShowChannelField(true);
    } else {
      setShowChannelField(false);
    }
    setFormData({ ...formData, processor: value });
  };

  const calendarIcon = () => {
    return (
      <div style={{ display: 'flex' }}>
        <Icon name="calendarIcon" />
        <div>Custom Range</div>
      </div>
    );
  };

  const closeSucessModal = () => {
    setModalView(modalViewsOptions.generate);
    resetForm();
    closeModal();
  };

  const closeErrorModal = () => {
    setModalView(modalViewsOptions.generate);
  };

  const onDateTimeClick = () => {
    setModalView(modalViewsOptions.datetime);
  };

  const onDateTimeRangeModalClose = () => {
    setModalView(modalViewsOptions.generate);
  };

  const disableGenerateReportButton = (data: ReportFormDataType, dateTimeRange: ReportDateTimeOptionsType[]): boolean => {
    if (data.currency?.length > 0 && data.processor?.length > 0 && dateTimeRange && dateTimeRange.length > 0) {
      return false;
    }
    return true;
  };

  const generateReport = async () => {
    setModalView(modalViewsOptions.loader);
    try {
      if (
        formData &&
        dateTimeOption &&
        dateTimeOption[0] &&
        dateTimeOption[0]?.value &&
        dateTimeOption[0]?.value.start_date.length > 0 &&
        dateTimeOption[0]?.value.end_date.length > 0
      ) {
        const payLoad: { currency: string; processor: string; channel?: string; start_date: string; end_date: string } = {
          currency: formData.currency,
          processor: formData.processor,
          start_date: dateTimeOption[0]?.value.start_date,
          end_date: dateTimeOption[0]?.value.end_date
        };
        if (formData.channel.length > 0 && reportProcessorToShowChannel.includes(formData.processor)) {
          payLoad.channel = formData.channel;
        }
        await generateReportMutation(payLoad);
        setModalView(modalViewsOptions.loader);
      }
    } catch (error) {
      logError(error);
    }
  };

  const resetForm = () => {
    setFormData({ currency: '', processor: '', channel: '' });
    setDateTimeOption([]);
    setShowChannelField(false);
  };

  const onDateTimeRangeFormat = (data: IAllDateTimeProps) => {
    if (data && data.startDate && data.meridiemStart && data.endDate && data.meridiemEnd) {
      const formatDateTime = `${dayjs(`${data.startDate},${data.startTime}`).format(
        'DD/MM/YYYY,hh:mm'
      )} ${data.meridiemStart?.toLowerCase()} - ${dayjs(`${data.endDate},${data.endTime} `).format(
        'DD/MM/YYYY, hh:mm'
      )} ${data.meridiemEnd?.toLowerCase()}`;
      const options: ReportDateTimeOptionsType[] = [
        {
          label: formatDateTime,
          value: {
            start_date: `${dayjs(`${data.startDate} ${data.startTime} ${data.meridiemStart?.toLowerCase()}`, 'YYYY-MM-DD hh:mm a')
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`,
            end_date: `${dayjs(`${data.endDate} ${data.endTime} ${data.meridiemEnd?.toLowerCase()}`, 'YYYY-MM-DD hh:mm a')
              .utc()
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`
          }
        }
      ];
      setDateTimeOption(options);
    } else {
      setDateTimeOption([]);
    }
  };

  const generatingLoadingContent = () => {
    return (
      <div className="generate_loading_wrapper">
        <div>
          {' '}
          <span className="spinner-border spinner-border-xs text-primary" role="status" aria-hidden="true" />
        </div>
        <h5 className="form-header">Generating report</h5>
        <p>Your processor report is being compiled</p>
      </div>
    );
  };
  const successfulContent = () => {
    return (
      <div className="generate_loading_wrapper">
        <Icon name="complete" />
        <h5 className="form-header">Success</h5>
        <p>Your processor report has been generated. Check your work email for link to download report.</p>
        <h5 className="dimissed-text" onClick={() => closeSucessModal()} aria-hidden="true" style={{ cursor: 'pointer' }}>
          Dismiss
        </h5>
      </div>
    );
  };
  const errorContent = () => {
    return (
      <div className="generate_loading_wrapper">
        <Icon name="errorInfoIcon" height={60} width={61} />
        <h5 className="form-header">Error</h5>
        <p>There has been an error in generating this report.Please try again</p>
        <h5 className="dimissed-text" onClick={() => closeErrorModal()} aria-hidden="true" style={{ cursor: 'pointer' }}>
          Back
        </h5>
      </div>
    );
  };
  const generateFormContent = () => {
    return (
      <div className="generate_report_modal_wrapper">
        {' '}
        <div className="form-group">
          <ReactSelectDropdown
            label="Currency Type"
            onChange={(value) => setFormData({ ...formData, currency: value as string })}
            placeholder="-- Select Currency --"
            options={currencyOptions}
            value={formData && formData?.currency && formData?.currency.length > 0 ? formData?.currency : ''}
          />
        </div>
        <div className="form-group">
          <ReactSelectDropdown
            label="Processor Name"
            onChange={(value) => onProcessorNameChange(value as string)}
            placeholder="-- Select processor --"
            options={reportProcessorNameOptions}
            value={formData && formData?.processor && formData?.processor.length > 0 ? formData?.processor : ''}
          />
        </div>
        {showChannelField && (
          <div className="form-group">
            <ReactSelectDropdown
              label="Channel"
              onChange={(value) => setFormData({ ...formData, channel: value as string })}
              placeholder="-- Select Channel--"
              options={channelOptions}
              value={formData && formData?.channel && formData?.channel.length > 0 ? formData?.channel : ''}
            />
          </div>
        )}
        <hr style={{ borderColor: '#DDE2EC', marginTop: '20px', marginBottom: '10px' }} />
        <div className="form-group">
          <ReactSelectDropdown
            label="Date & Time Range"
            placeholder={calendarIcon()}
            options={dateTimeOption}
            menuIsOpen={false}
            onFocus={() => onDateTimeClick()}
            value={dateTimeOption.length > 0 ? dateTimeOption[0].value : null}
          />
        </div>
        <hr style={{ borderColor: '#DDE2EC', marginTop: '20px', marginBottom: '10px' }} />
        <div className="onboarding-text">Generated reports will be available for download and will be sent to the group email.</div>
      </div>
    );
  };
  const modalOptions = {
    shared: {
      close: () => {
        setModalView(modalViewsOptions.generate);
        resetForm();
        closeModal();
      }
    },
    generate: {
      size: 'md',
      heading: 'Generate Processor Report',
      description:
        'Processor Report is a record of all transactions in the Kora collections account with different processors. Enter your preferred date & time range to get report for that period.',
      headerBottomBorder: true,
      content: generateFormContent(),
      secondButtonActionIsTerminal: false,
      secondButtonText: 'Generate Report',
      secondaryCompletedModal: false,
      secondButtonAction: () => {
        generateReport();
      },

      secondButtonDisable: disableGenerateReportButton(formData, dateTimeOption),
      firstButtonAction: () => {
        resetForm();
        closeModal();
      }
    },
    loader: {
      size: 'sm',
      heading: '',
      description: '',
      headerBottomBorder: false,
      content: generatingLoadingContent(),
      showButtons: false,
      formCenter: true
    },
    success: {
      size: 'sm',
      heading: '',
      description: '',
      headerBottomBorder: false,
      content: successfulContent(),
      showButtons: false,
      formCenter: true
    },
    error: {
      size: 'sm',
      heading: '',
      description: '',
      headerBottomBorder: false,
      content: errorContent(),
      showButtons: false,
      formCenter: true
    }
  };
  const modalProps = { ...modalOptions.shared, ...modalOptions[modalView] };

  return (
    <>
      <CustomDateRangeCalendar
        visible={modalView === modalViewsOptions.datetime}
        close={onDateTimeRangeModalClose}
        selectedDate={selectedDate}
        setSelectedDateRange={value => {
          if (value && value?.allData) {
            onDateTimeRangeFormat(value.allData);
          }
        }}
        showTimeRange
        showMaxEndDate={false}
      />
      <Modal
        {...modalProps}
        visible={
          (visible && modalView === modalViewsOptions.generate) ||
          (visible && modalView === modalViewsOptions.loader) ||
          (visible && modalView === modalViewsOptions.success) ||
          (visible && modalView === modalViewsOptions.error)
        }
      />
    </>
  );
};
export default GenerateReportModal;
