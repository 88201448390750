import React, { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useQuery } from 'react-query';

import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { getDate, getTime, logError } from '+utils';

import { TKycHistoryData } from '../TypesAccountHolders';

import cancelIcon from '+assets/img/dashboard/cancel.svg';
import checkIcon from '+assets/img/dashboard/check-green.svg';

const api = new APIRequest();

const KycHistory: FC<{ id: string; setKycHistoryLen: Dispatch<SetStateAction<number>> }> = ({ id, setKycHistoryLen }) => {
  const { feedbackInit } = useFeedbackHandler();

  const { data, isFetching } = useQuery(['KYC_HISTORY', id], () => api.getAccountHolderKycHistory(id), {
    onError: (error: { response: { data: { message: string } } }) => {
      logError(error);
      feedbackInit({
        message: error?.response.data.message,
        type: 'danger'
      });
    }
  });

  useEffect(() => setKycHistoryLen(data?.rows.length || 0), [data?.rows.length]);

  return (
    <div data-testid="kyc-rejections">
      {isFetching ? (
        <LoadingPlaceholder content={2} type="table" />
      ) : (
        (data?.rows.length &&
          data.rows.map((history: TKycHistoryData) => (
            <div className="kyc-info__rejected" data-testid="kyc-rejection-row" key={history.created_at}>
              <div>
                <div className="title-wrapper">
                  <img src={history.status === 'approved' ? checkIcon : cancelIcon} alt="kyc history icon" />{' '}
                  <span className="grey-text">{history.status === 'approved' ? 'KYC Approved' : 'KYC Rejected'}</span>
                </div>
                {history.decline_reason && <p>{history.decline_reason}</p>}
              </div>
              <div className="timestamp">
                <span>{getDate(history.created_at)}</span>
                <span style={{ color: '#A9AFBC', marginLeft: 6 }}>{getTime(history.created_at)}</span>
              </div>
            </div>
          ))) || <span className="grey-text no-rejections">No previous action have been recorded at the moment</span>
      )}
    </div>
  );
};

export default KycHistory;
