/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prettier/prettier */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
import React, { ElementRef, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { IError, MerchantCurrencyModalType, modalState, updateProductConfigData } from '+/types';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import ListDropdown from '+shared/ListDropdown';
import Modal, { IModalProps } from '+shared/Modal';
import { capitalizeRemovedash, logError } from '+utils';

import WarningIcon from '+assets/img/dashboard/information-button-disable.svg';
import InformationIcon from '+assets/img/dashboard/information-button-enable.svg';

import '../index.scss';

const apiRequest = new APIRequest();

type ModalType = modalState;
type SelectedStateType = updateProductConfigData['type'] | 'enable-custom-merchants' | 'disable-custom-merchants';
type MerchantListType = {
  id: number;
  name: string;
};
type MerchantCacheType = {
  list: MerchantListType[];
  cache: {
    [key in MerchantListType['id']]: boolean;
  };
};

const reverseProductMapping = {
  collection: 'Pay-ins',
  disbursement: 'Payouts'
};

const configurationMode = {
  default: 'default',
  all_merchants: 'default and custom'
} as const;

const MerchantCurrencyModal = ({
  status,
  activeModal,
  setActiveModal,
  currency,
  merchantId,
  productType,
  method,
  hideButton = false
}: MerchantCurrencyModalType) => {
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const wrapperRef = useRef<ElementRef<'div'>>(null);
  const [manageModal, setManageModal] = useState(false);
  const [modal, setModal] = useState('');
  const [selectedState, setSelectedState] = useState<SelectedStateType>();
  const [consent, setConsent] = useState(false);
  const [state, setState] = useState({
    activePage: false,
    merchantList: [{}],
    merchantDropdownVisible: false,
    singleMerchantConsent: false,
    paginationPage: 1,
    sortingParams: {}
  });
  const radioLabel = {
    default: 'for only merchants under the default configuration',
    all_merchants: 'for all merchants'
  };

  const disableSingleTypes = ['disable-one', 'disable-product', 'disable-channel'];
  const product = reverseProductMapping[productType as keyof typeof reverseProductMapping];
  const formattedPaymentMethod = capitalizeRemovedash((method || '') as string);

  const selectedMode = configurationMode[selectedState as keyof typeof configurationMode] ?? 'custom';

  const merchantType = () => {
    if (selectedState === 'all_merchants') {
      return 'All Merchants';
    }
    if (selectedState?.includes('custom')) {
      return 'Custom Merchants';
    }
    return `Default Merchants`;
  };

  const filterMerchantsList = (list: MerchantListType[]) => {
    if (!list?.length) return [];
    const merchants: MerchantCacheType = {
      list: [],
      cache: {}
    };
    for (let i = 0; i < list.length; i += 1) {
      if (!merchants.cache[list[i].id]) {
        merchants.cache[list[i].id] = true;
        merchants.list.push(list[i]);
      }
    }
    return merchants.list;
  };

  const {
    isFetching: isFetchingMerchants,
    refetch: refetchMerchants,
    data
  } = useQuery(['MERCHANTS'], () => apiRequest.getAllMerchants(), {
    keepPreviousData: true,
    select: res => filterMerchantsList(res?.data),
    onError: () => {
      feedbackInit({
        message: 'There has been an error getting all merchants',
        type: 'danger',
        action: {
          action: () => refetchMerchants(),
          name: 'Try again'
        }
      });
    }
  });
  useEffect(() => {
    if (!data?.length) return;
    setState(prevState => ({
      ...prevState,
      merchantList: data
    }));
  }, [data]);

  const updateProductConfiguration = useMutation((value: updateProductConfigData) => apiRequest.updateProductConfiguration(value), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${currency}_PRODUCT`]);
      queryClient.invalidateQueries([`${currency}_PRODUCT`, merchantId, currency, `${product?.toLowerCase()}`]);
      queryClient.invalidateQueries([`${currency}_PRODUCT`, merchantId, currency]);
      queryClient.invalidateQueries([`${currency}_PRODUCTS`, currency, `${product?.toLowerCase()}`]);
      queryClient.invalidateQueries([`${currency}_PRODUCTS`, currency, `${product?.toLowerCase()}`, method]);
      queryClient.invalidateQueries([`${currency}_PRODUCTS`, currency]);
      queryClient.invalidateQueries(`${currency}_MERCHANTS`);
      queryClient.invalidateQueries([`${currency}_PRODUCT_CONFIG`, currency, `${product?.toLowerCase()}`]);
      queryClient.invalidateQueries([`${currency}_PRODUCT_CONFIG`, currency, `${product?.toLowerCase()}`, method]);
    },
    onError: error => {
      logError(error);
      feedbackInit({
        message: `${(error as IError)?.response?.data?.message ?? 'There has been an error updating configuration'}`,
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const allAccessContent = (type: ModalType) => {
    const isCustom = type?.includes('customs');
    const content: SelectedStateType[] = isCustom ? ['enable-custom-merchants', 'disable-custom-merchants'] : ['default', 'all_merchants'];

    const renderLabelText = (current: SelectedStateType) => {
      if (isCustom) {
        return `${current === 'enable-custom-merchants' ? 'Enable' : 'Disable'} for only custom merchants`;
      }
      return `${['enable-all', 'enable-product-all', 'enable-channels'].includes(type as string) ? 'Enable ' : 'Disable '}${
        radioLabel[current as keyof typeof radioLabel]
      }`;
    };
    return (
      <div className="currency-modal__content">
        <div className="radio_container">
          {content.map(item => {
            return (
              <label key={item}>
                <input checked={item === selectedState} type="radio" onChange={() => setSelectedState(item)} />
                {renderLabelText(item)}
              </label>
            );
          })}
        </div>
      </div>
    );
  };

  const singleMerchantAccessContent = (type: ModalType) => {
    return (
      <div className="currency-modal__content">
        <div className={`merchant--select ${state.merchantDropdownVisible ? '--active' : ''}`}>
          <h6>Select merchant</h6>
          <div className={`merchant--select-w ${state.merchantDropdownVisible ? '--active' : ''}`}>
            <ListDropdown
              type="Merchant"
              list={state.merchantList}
              isFetching={isFetchingMerchants}
              value={state.sortingParams?.['filter[merchant]' as keyof typeof state.sortingParams] || ''}
              defaultValue="All Merchants"
              active={state.merchantDropdownVisible}
              useCoreId
              setActive={value => {
                setState(prevState => ({
                  ...prevState,
                  merchantDropdownVisible: Boolean(value)
                }));
              }}
              setValue={value => {
                if (!state.activePage) {
                  setState(prevState => ({
                    ...prevState,
                    activePage: true
                  }));
                }
                setState(prevState => ({
                  ...prevState,
                  merchantDropdownVisible: false,
                  paginationPage: 1,
                  sortingParams: {
                    'filter[merchant]': value === 'all' ? undefined : value
                  }
                }));
              }}
            />
          </div>
        </div>
        {state.sortingParams?.['filter[merchant]' as keyof typeof state.sortingParams] && !state.merchantDropdownVisible ? (
          <>
            <div className="info-wrapper">
              <img src={type === 'enable' ? InformationIcon : WarningIcon} alt="warning" />
              <p className={type as string}>
                <span>Important:</span> This means that this merchant will {type === 'enable' ? 'now' : 'not'} be able to receive or send
                out
                {` ${currency}`} payments and will {type === 'enable' ? '' : 'not'} have the default {currency} configuration for all
                merchants
              </p>
            </div>
            <label className="prod-consent-wrapper">
              <input
                key={Math.random()}
                name="singleMerchantConsent"
                checked={state.singleMerchantConsent}
                type="checkbox"
                onChange={() => setState(prev => ({ ...prev, singleMerchantConsent: !prev.singleMerchantConsent }))}
              />
              <span>Yes, I understand the implications of this action</span>
            </label>
          </>
        ) : null}
      </div>
    );
  };

  const modalDetails = (type: ModalType) => {
    let content;
    const isCustom = ['customs', 'customs-product'].includes(type as string);

    const isEnableTypes = ['enable-all', 'enable-product-all', 'enable-channels'].includes(type as string);
    const isEnableConfirmTypes = ['enable-confirm', 'enable-one', 'enable-product', 'enable-channel'].includes(type as string);
    const enableSingleTypes = ['enable-one', 'enable-product', 'enable-channel'];

    const singleTypes = ['enable-one', 'disable-one', 'enable-product', 'disable-product', 'enable-channel', 'disable-channel'];
    const channelTypes = ['enable-channel', 'disable-channel', 'enable-channels', 'disable-channels'];
    const singleMerchantHeading = `${
      ['enable-product', 'disable-product', ...channelTypes].includes(type as string)
        ? `${channelTypes.includes(type as string) ? formattedPaymentMethod : product}`
        : currency
    }`;
    const productOrMethod = `${
      ['enable-product-all', 'disable-product-all', 'customs-product'].includes(type as string) ? product : `${formattedPaymentMethod}`
    }`;
    const methodProduct = () => {
      if (channelTypes.includes(type as string)) {
        return formattedPaymentMethod;
      }
      return formattedPaymentMethod || product || '';
    };

    const allMerchantsHeading = `${
      type === 'enable-confirm' ? 'Enable' : 'Disable'
    } ${currency} ${methodProduct()} Access for ${merchantType()}?`;

    switch (type) {
      case 'enable':
      case 'disable':
        content = {
          heading: `${type === 'enable' ? 'Enable' : 'Disable'} ${currency} Access for a Merchant`,
          description: 'Select a merchant from our merchant list',
          secondButtonText: 'Continue',
          secondButtonDisable: !(
            state.singleMerchantConsent && state.sortingParams?.['filter[merchant]' as keyof typeof state.sortingParams]
          ),
          completedDescription: `You have granted this merchant ${currency} access successfully.`,
          completedHeading: `Access ${type === 'enable' ? 'granted' : ' disabled'}`,
          content: singleMerchantAccessContent(type),
          secondButtonAction: () => {
            if (type === 'enable') {
              return updateProductConfiguration.mutateAsync({
                currency,
                type: 'single_merchant',
                data: {
                  enabled: true
                },
                account_id: state.sortingParams?.['filter[merchant]' as keyof typeof state.sortingParams]
              });
            }

            return updateProductConfiguration.mutateAsync({
              currency,
              type: 'single_merchant',
              data: {
                enabled: false
              },
              account_id: state.sortingParams?.['filter[merchant]' as keyof typeof state.sortingParams]
            });
          }
        };
        break;
      case 'enable-all':
      case 'disable-all':
      case 'enable-product-all':
      case 'disable-product-all':
      case 'enable-channels':
      case 'disable-channels':
      case 'customs':
      case 'customs-product':
        content = {
          heading: isCustom
            ? `Control ${currency} ${productOrMethod} Access for Custom Merchants`
            : `${isEnableTypes ? 'Enable' : 'Disable'}  ${currency} ${productOrMethod} Access for all Merchants?`,
          description: 'Please select an option to continue',
          secondButtonText: 'Continue',
          content: allAccessContent(type),
          secondButtonDisable: !selectedState,
          secondButtonActionIsTerminal: !!['enable-one', 'disable-one'].includes(type),
          secondButtonAction: () => {
            if (isCustom) {
              if (selectedState === 'enable-custom-merchants') {
                setModal('enable-confirm');
              } else {
                setModal('disable-confirm');
              }
            } else if (isEnableTypes) {
              setModal('enable-confirm');
            } else {
              setModal('disable-confirm');
            }
          },
          size: 'mdBase'
        };
        break;

      case 'enable-confirm':
      case 'disable-confirm':
      case 'enable-one':
      case 'disable-one':
      case 'enable-product':
      case 'disable-product':
      case 'enable-channel':
      case 'disable-channel':
        content = {
          heading: singleTypes.includes(type)
            ? `${enableSingleTypes.includes(type) ? 'Enable' : 'Disable'} ${singleMerchantHeading} for this merchant`
            : allMerchantsHeading,
          description: `Please confirm that you want to ${
            isEnableConfirmTypes ? 'enable' : ' disable'
          } ${currency} ${methodProduct()} access for ${singleTypes.includes(type) ? 'this merchant.' : merchantType()}  `,
          completedHeading: `Access ${isEnableConfirmTypes ? 'Enabled' : ' Disabled'}`,
          completedDescription: singleTypes.includes(type)
            ? `You have ${
                enableSingleTypes.includes(type) ? 'enabled' : ' disabled'
              } this merchant’s ${currency} ${methodProduct()} access successfully`
            : `You have ${
                type === 'enable-confirm' ? 'enable' : ' disable'
              } all merchants ${currency}  ${methodProduct()} access successfully.`,
          secondButtonText: `${['enable-confirm', ...enableSingleTypes].includes(type) ? 'Enable' : 'Disable'} Access`,
          secondButtonDisable: !consent,
          content: (
            <div className="currency-modal__content">
              <div className="info-wrapper">
                <img src={['enable-confirm', ...enableSingleTypes].includes(type) ? InformationIcon : WarningIcon} alt="warning" />
                {singleTypes.includes(type) ? (
                  <p className={type}>
                    <span>Important:</span> This means that this merchant{' '}
                    {selectedState ? `under the <br /> ${selectedMode} configuration will be` : 'will'}{' '}
                    {['enable-confirm', ...enableSingleTypes].includes(type) ? '' : 'not be'} able to{' '}
                    {productType === 'collection' ? 'receive' : 'send out'} {currency} {methodProduct()} payments.
                  </p>
                ) : (
                  <p className={type}>
                    <span>Important:</span> This means that {['enable-confirm', ...enableSingleTypes].includes(type) ? 'all' : 'no'}{' '}
                    merchants under the <br /> {selectedMode} configuration will be able to{' '}
                    {productType === 'collection' ? 'receive' : 'send out'} {currency} {methodProduct()} payments.
                  </p>
                )}
              </div>
              <label className="prod-consent-wrapper">
                <input checked={consent} type="checkbox" onChange={() => setConsent(!consent)} />
                <span>Yes, I understand the implications of this action</span>
              </label>
            </div>
          ),
          secondButtonAction: async () => {
            const selectedType = (
              selectedState?.includes('custom') ? 'custom_merchants' : selectedState
            ) as updateProductConfigData['type'];
            if (['enable-confirm', ...enableSingleTypes].includes(type)) {
              return updateProductConfiguration.mutateAsync({
                currency,
                type: enableSingleTypes.includes(type) ? 'single_merchant' : selectedType,
                data: {
                  enabled: true
                },
                account_id: merchantId,
                payment_type: productType,
                payment_method: method
              });
            }

            return updateProductConfiguration.mutateAsync({
              currency,
              type: disableSingleTypes.includes(type) ? 'single_merchant' : selectedType,
              data: {
                enabled: false
              },
              account_id: merchantId,
              payment_type: productType,
              payment_method: method
            });
          },
          size: 'mdBase'
        };

        break;
      default:
        content = {};
    }

    return {
      close: () => {
        setModal('');
        setConsent(false);
        setActiveModal(null);
        setSelectedState(undefined);
        setState(prev => ({
          ...prev,
          merchantDropdownVisible: false,
          singleMerchantConsent: false,
          sortingParams: {}
        }));
      },
      secondaryCompletedModal: true,
      ...content
    };
  };

  const handleClickOutside = (event: unknown) => {
    if (wrapperRef.current && !wrapperRef.current.contains((event as React.ChangeEvent<HTMLDivElement>).target)) {
      setManageModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  useEffect(() => {
    if (activeModal) {
      setModal(activeModal);
    }
  }, [activeModal]);

  return (
    <>
      {!hideButton && (
        <div ref={wrapperRef}>
          <button
            aria-label="more"
            type="button"
            className="btn btn-secondary --enable-btn --more-btn"
            onClick={() => {
              setManageModal(value => !value);
            }}
            title="more"
          >
            <div className="header-actions">
              <i className="os-icon os-icon-more-horizontal" />
            </div>
          </button>
          {manageModal && (
            <ul className="element-box ellipsis__nav">
              {status === 'inactive' && (
                <li
                  role="presentation"
                  className="ellipsis__item"
                  onClick={() => {
                    setModal('enable-all');
                    setManageModal(false);
                  }}
                >
                  <span className="active">{`Enable ${currency} Access for all Merchants`}</span>
                </li>
              )}

              {status === 'active' && (
                <li
                  role="presentation"
                  className="ellipsis__item"
                  onClick={() => {
                    setModal('disable-all');
                    setManageModal(false);
                  }}
                >
                  <span className="disabled">{`Disable ${currency} Access for all Merchants`}</span>
                </li>
              )}
              <li
                role="presentation"
                className="ellipsis__item"
                onClick={() => {
                  if (productType) {
                    setModal('customs-product');
                  } else {
                    setModal('customs');
                  }

                  setManageModal(false);
                }}
              >
                <span className="active">Controls for Custom Merchants</span>
              </li>
            </ul>
          )}
        </div>
      )}

      <Modal
        visible={!!modal}
        secondButtonColor={['disable', 'disable-confirm', 'disable-all', ...disableSingleTypes].includes(modal) ? 'red' : ''}
        size="md"
        completedModalSize="base"
        equalFooterBtn
        showCompleteActionText
        {...(modalDetails(modal as ModalType) as IModalProps)}
      />
    </>
  );
};

export default MerchantCurrencyModal;
