/* eslint-disable no-nested-ternary */
import { capitalizeRemovedash, switchCurrency } from '+utils';
import { Field, Formik } from 'formik';
import React from 'react';
import CustomCheckbox from './CustomCheckbox';
import CustomRadio from './CustomRadio';

interface INewFeesComponentProps {
  setDisabled: (disabled: boolean) => void;
  setValues: (values: any) => void;
  currencies: any[];
  activeCurrency: string;
}
export default function NewFeesComponent({ setDisabled, setValues, currencies, activeCurrency }: INewFeesComponentProps) {
  const paymentCategory = {
    payin: {
      title: 'Pay-ins',
      value: 'charge'
    },
    payout: {
      title: 'Payouts',
      value: 'disburse'
    },
    mobile_money: {
      title: 'Mobile Money',
      value: 'disburse'
    }
  };
  const paymentChannels = [
    { label: 'All', value: 'ALL' },
    { label: 'FPS', value: 'FPS' },
    { label: 'SEPA', value: 'SEPA' },
    { label: 'ACH', value: 'ACH' },
    { label: 'CHAPS', value: 'CHAPS' },
    { label: 'BACS', value: 'BACS' },
    { label: 'FEDWIRE', value: 'FEDWIRE' },
    { label: 'SWIFT', value: 'SWIFT' }
  ];
  
  const kindCategory = {
    charge: 'payin',
    disburse: 'payout'
  };
  const kindCategorySwitch = {
    payin: 'charge',
    payout: 'disburse'
  };

  const VATPercentage = {
    NGN: 7.5,
    GHS: 15,
    KES: 16,
    USD: 7.5,
    ZAR: 15,
    EUR: 7.5,
    GBP: 7.5,
    XAF: 19.5,
    XOF: 18
  };
  const getCurrencyValues = (selectedCurrency) => {
    if (!currencies) {
      return { paymentKind: 'charge', paymentType: 'bank_transfer' };
    }
    const currencyData = currencies.find((currency) => currency.code === selectedCurrency);
    const paymentTypes = currencyData.meta_data.payment_types;
    const possibleKinds = Object.keys(paymentTypes);
    const activeKind = possibleKinds.find((kind) => paymentTypes[kind]?.length > 0);
    const paymentKind = kindCategorySwitch[activeKind];
    const paymentType = paymentTypes[activeKind][0].value;
    return { paymentKind, paymentType };
  };
  return (
    <Formik
      initialValues={{
        currency: activeCurrency,
        kind: getCurrencyValues(activeCurrency).paymentKind,
        trxnType: getCurrencyValues(activeCurrency).paymentType,
        type: 'flat',
        trxnChannel: '',
        default: false,
        minimum: '',
        maximum: '',
        percent: '',
        amount: '',
        cap: 0,
        vat: false,
        percentFlat: ''
      }}
      validate={(values) => {
        const errors = {};
        if (values.cap < 0) errors.cap = 'A valid fee cap must be greater than or equal to 0';
        if ((values.percent === '' && values.type === 'percent') || parseFloat(values.percent) < 0 || parseFloat(values.percent) >= 100)
          errors.percent = 'A valid percent rule is required';
        if (!values.vat && parseFloat(values.percent) + 7.5 >= 100)
          errors.percent = 'Since vat is not included, this percent rule exceeds 100';

        if (values.amount === '' && values.type === 'flat') errors.amount = 'A valid flat amount rule is required';
        if (parseInt(values.amount, 10) < 0 && values.type === 'flat')
          errors.amount = 'A valid flat amount must be greater than or equal to 0';

        if (Number.isNaN(parseFloat(values.minimum))) errors.minimum = 'A minimum value has to be set for this fee rule';
        if (Number.isNaN(parseFloat(values.maximum)) || parseFloat(values.maximum) < 1)
          errors.maximum = 'A valid maximum value has to be set for this fee rule';
        if (parseInt(values.minimum, 10) < 0) errors.minimum = 'A minimum value must be greater than or equal to 0';
        if (parseInt(values.maximum, 10) < 0) errors.maximum = 'A maximum value must be greater than or equal to 0';

        if (!values.vat && values.type === 'flat' && 1.075 * parseInt(values.amount, 10) > parseInt(values.maximum, 10))
          errors.amount = 'A valid flat amount (vat inclusive) must be less than the maximum value';

        if (values.cap && values.cap > parseInt(values.maximum, 10)) errors.cap = 'A valid fee cap must be less than the maximum value';
        if (values.type === 'flat' && parseInt(values.amount, 10) > parseInt(values.maximum, 10))
          errors.amount = 'A valid flat amount must be less than the maximum value';

        if (values.minimum === values.maximum || values.minimum > values.maximum)
          errors.maximum = 'Maximum amount must be greater than the minimum amount';

        if (values.scheme === '' && ['GBP', 'EUR', 'USD'].includes(activeCurrency)) errors.scheme = 'Select a payment channel';
        const disabled = () => {
          if (
            (values.type === 'percent' && errors.percent) ||
            (values.type === 'flat' && errors.amount) ||
            errors.scheme ||
            errors.minimum ||
            errors.maximum ||
            errors.cap
          )
            return true;
          return false;
        };
        setDisabled(disabled());
        setValues(values);
        return errors;
      }}
    >
      {({ values, errors, touched, setFieldValue }) => {
        return (
          <div className="form-center col-sm-12">
            <fieldset className="form-group my-1">
              <div className="form-group">
                <div className="row">
                  <div className="col-sm-12">
                    <p>What type of fee are you charging?</p>
                  </div>
                  <div className="col-sm-3">
                    <CustomRadio
                      text="Flat fee"
                      name="type"
                      checked={values.type === 'flat'}
                      onChange={() => {
                        setFieldValue('type', 'flat');
                        setFieldValue('cap', 0);
                        setFieldValue('percent', '');
                        setFieldValue('amount', '');
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <CustomRadio
                      text="Percentage Fee"
                      name="type"
                      checked={values.type === 'percent'}
                      onChange={() => {
                        setFieldValue('type', 'percent');
                        setFieldValue('cap', 0);
                        setFieldValue('percent', '');
                        setFieldValue('amount', '');
                      }}
                    />
                  </div>
                  <div className="col-sm-4" hidden={!['GBP', 'EUR', 'USD'].includes(activeCurrency)}>
                    <CustomRadio
                      text="Percentage + Flat Fee"
                      name="type"
                      checked={values.type === 'percentFlat'}
                      onChange={() => {
                        setFieldValue('type', 'percentFlat');
                        setFieldValue('cap', 0);
                        setFieldValue('percent', '');
                        setFieldValue('amount', '');
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group" hidden={!['GBP', 'EUR', 'USD'].includes(activeCurrency)}>
                <Field name="scheme">
                  {({ field }) => (
                    <>
                      <label htmlFor="payment-type">Payment Channel</label>
                      <select
                        {...field}
                        component="select"
                        className="form-control"
                        name="scheme"
                        onChange={(e) => {
                          setFieldValue('scheme', e.target.value);
                        }}
                      >
                        <option value="">Select Payment Channel</option>
                        {paymentChannels?.map((method) => (
                          <option value={method.value} key={method.value}>
                            {method.label}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </Field>
                {touched.amount && errors.scheme && (
                  <div className="input__errors">
                    <p>{errors.scheme}</p>
                  </div>
                )}
              </div>
              <div className="form-group mb-3" hidden={!['GBP', 'EUR', 'USD'].includes(activeCurrency)}>
                <CustomCheckbox
                  text="Set as the default fee for this payment channel?"
                  checked={values.default}
                  onChange={(value) => {
                    setFieldValue('default', value);
                  }}
                  className="default-payment-channel"
                />
              </div>

              <div className="form-group">
                <Field name="currency">
                  {({ field }) => (
                    <>
                      <label htmlFor="currency">Currency</label>
                      <select
                        {...field}
                        component="select"
                        className="form-control"
                        name="currency"
                        onChange={(e) => {
                          const { paymentKind, paymentType } = getCurrencyValues(e.target.value);
                          setFieldValue('currency', e.target.value);
                          setFieldValue('kind', paymentKind);
                          setFieldValue('trxnType', paymentType);
                        }}
                      >
                        {currencies?.map((currency) => {
                          const { id: currencyId, code } = currency;
                          return (
                            <option key={`currency_${currencyId}`} value={code}>
                              {switchCurrency[code]}
                            </option>
                          );
                        })}
                      </select>
                    </>
                  )}
                </Field>
              </div>

              <div className="form-group">
                <Field name="kind">
                  {({ field }) => (
                    <>
                      <label htmlFor="kind">Payment Category</label>
                      <select
                        {...field}
                        component="select"
                        className="form-control"
                        name="kind"
                        onChange={(e) => {
                          setFieldValue('kind', e.target.value);
                          // if (e.target.value === 'disburse') setFieldValue('trxnType', 'bank_account');
                        }}
                      >
                        {currencies?.map((currency) => {
                          if (currency.code === values.currency) {
                            const categories = Object.keys(currency?.meta_data?.payment_types || {});
                            return (
                              <React.Fragment key={currency.code}>
                                {categories?.map((category) => {
                                  if (!currency?.meta_data?.payment_types?.[category].length) return null;
                                  return (
                                    <option value={paymentCategory[category].value} key={paymentCategory[category].value}>
                                      {paymentCategory[category].title}
                                    </option>
                                  );
                                })}
                              </React.Fragment>
                            );
                          }
                          return null;
                        })}
                      </select>
                    </>
                  )}
                </Field>
              </div>

              <div className="form-group">
                <Field name="trxnType">
                  {({ field }) => (
                    <>
                      <label htmlFor="payment-type">Payment Type</label>
                      <select
                        {...field}
                        component="select"
                        className="form-control"
                        name="trxnType"
                        onChange={(e) => {
                          setFieldValue('trxnType', e.target.value);
                        }}
                      >
                        {currencies?.map((currency) => {
                          if (currency.code === values.currency) {
                            const paymentMethods = currency?.meta_data?.payment_types[kindCategory[values.kind]];
                            return (
                              <React.Fragment key={currency.code}>
                                <option value="">Select Payment Type</option>
                                {paymentMethods?.map((method) => (
                                  <option value={method.value} key={method.value}>
                                    {capitalizeRemovedash(method.value)}
                                  </option>
                                ))}
                              </React.Fragment>
                            );
                          }
                          return null;
                        })}
                      </select>
                    </>
                  )}
                </Field>
              </div>

              <hr className="mt-4" />

              <div className="row">
                <div className="col-sm-12">
                  <p>What transaction range should this fee apply to?</p>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <Field name="minimum">
                      {({ field }) => (
                        <>
                          <label htmlFor="percent">From</label>
                          <input
                            {...field}
                            name="minimum"
                            value={values.minimum}
                            placeholder={values.currency}
                            className="form-control"
                            type="number"
                          />
                          {touched.minimum && errors.minimum && (
                            <div className="input__errors">
                              <p>{errors.minimum}</p>
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <Field name="maximum">
                      {({ field }) => (
                        <>
                          <label htmlFor="amount">To</label>
                          <input {...field} placeholder={values.currency} name="maximum" className="form-control" type="number" />

                          {touched.maximum && errors.maximum && (
                            <div className="input__errors">
                              <p>{errors.maximum}</p>
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              </div>

              <hr className="mt-1" />

              <div className="row" hidden={values.type === 'flat'}>
                <div className="col-sm-6">
                  <div className="form-group">
                    <Field name="percent">
                      {({ field }) => (
                        <>
                          <label htmlFor="percent">Percentage fee (%)</label>
                          <input {...field} name="percent" className="form-control" type="number" placeholder={0} />
                          {touched.percent && errors.percent && (
                            <div className="input__errors">
                              <p>{errors.percent}</p>
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="col-sm-6" hidden={values.type === 'percentFlat'}>
                  <div className="form-group">
                    <Field name="cap">
                      {({ field }) => (
                        <>
                          <label htmlFor="cap">Max. Fee Limit (Capped at)</label>
                          <input {...field} name="cap" className="form-control" type="number" placeholder={values.currency} />
                          {touched.cap && errors.cap && (
                            <div className="input__errors">
                              <p>{errors.cap}</p>
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="form-group col-sm-6" hidden={values.type !== 'percentFlat'}>
                <Field name="amount">
                  {({ field }) => (
                    <>
                      <label htmlFor="amount">Added flat fee</label>
                      <input {...field} name="amount" className="form-control" type="number" placeholder={values.currency} />
                      {touched.amount && errors.amount && (
                        <div className="input__errors">
                          <p>{errors.amount}</p>
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </div>
              </div>

              <div className="form-group" hidden={values.type !== 'flat'}>
                <Field name="amount">
                  {({ field }) => (
                    <>
                      <label htmlFor="amount">Flat Transaction Fee</label>
                      <input {...field} name="amount" className="form-control" type="number" placeholder={values.currency} />
                      {touched.amount && errors.amount && (
                        <div className="input__errors">
                          <p>{errors.amount}</p>
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </div>

              <div className="form-group mb-3" hidden={['GBP', 'EUR', 'USD'].includes(activeCurrency)}>
                <CustomCheckbox
                  text="Tax (VAT) has been considered and included in this fee"
                  checked={values.vat}
                  onChange={(value) => {
                    setFieldValue('vat', value);
                  }}
                />
              </div>

              <hr />

              <div className="sub-info-detail" hidden={['GBP', 'EUR', 'USD'].includes(activeCurrency)}>
                <span>
                  {values.vat
                    ? `This fee already includes the VAT of ${
                        VATPercentage[values.currency]
                      }%, which means that the VAT will not be added to the fee when the customer is charged.`
                    : `This fee doesn't include the VAT of ${
                        VATPercentage[values.currency]
                      }%, which means that the VAT will be added to the fee when the customer is
                    charged.`}
                </span>
              </div>
            </fieldset>
          </div>
        );
      }}
    </Formik>
  );
}
