import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useFeedbackHandler, useQueryStorage, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import { CurrencyType, productCategoriesType } from '+types';
import {
  availableProductCategories,
  capitalize,
  formatProductConfigStatus,
  history,
  storageDataKey,
  switchCurrencyWithoutSymbol,
  switchTrxnMessage
} from '+utils';

import Categories from './Categories';

import './index.scss';

const api = new APIRequest();

const ProductGroup = () => {
  const searchQuery = useSearchQuery<{ currency: CurrencyType; product: productCategoriesType }>();
  const { getQueryData } = useQueryStorage();
  const { feedbackInit } = useFeedbackHandler();
  const { currency } = useParams<{ currency: CurrencyType }>();
  const availableCurrencies = getQueryData(storageDataKey.AVAILABLE_CURRENCIES);
  const activeCurrency = searchQuery.value.currency ?? currency;
  const productTab = searchQuery.value.product ?? 'pay-ins';

  const {
    data: configData,
    refetch: refetchConfig,
    isLoading
  } = useQuery([`${currency}_PRODUCTS`, activeCurrency], () => api.getProductConfiguration(activeCurrency, {}), {
    refetchOnMount: 'always',
    onError: () => {
      feedbackInit({
        message: `There has been an error in getting this currency's config`,
        type: 'danger',
        action: {
          action: () => refetchConfig(),
          name: 'Try again'
        }
      });
    }
  });

  const currencyStatus = configData?.data?.setting?.enabled;

  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <button type="button" className="btn btn-link mb-2" onClick={() => history.goBack()}>
            <i className="os-icon os-icon-arrow-left7" />
            <span>Back to Product Config</span>
          </button>
        </div>
        <div className="content">
          <div className="first-section">
            <div className="title-wrapper">
              <span>
                <h4 className="title">{`${switchCurrencyWithoutSymbol[activeCurrency]} Merchants [${activeCurrency}]`}</h4>
                {!isLoading && (
                  <span
                    className="status"
                    style={{
                      color: switchTrxnMessage[formatProductConfigStatus(String(currencyStatus))]?.color,
                      backgroundColor: switchTrxnMessage[formatProductConfigStatus(String(currencyStatus))]?.backgroundColor
                    }}
                  >
                    {switchTrxnMessage[formatProductConfigStatus(String(currencyStatus))]?.name}
                  </span>
                )}
              </span>
              <p className="subtitle">Here’s a comprehensive list of products under this currency.</p>
            </div>
            <div className="controls">
              <CurrencyPicker
                options={availableCurrencies as CurrencyType[]}
                onChange={value => {
                  searchQuery.setQuery({ currency: value as CurrencyType });
                }}
                className="business-settings__currency-switch"
                activeCurrency={activeCurrency.toUpperCase()}
                label={null}
              />
            </div>
          </div>
          <section className="os-tabs-w">
            <div className="os-tabs-controls os-tabs-complex">
              <ul className="nav nav-tabs" role="tablist">
                {Object.entries(availableProductCategories).map(([key, value]) => {
                  return (
                    <li className="nav-item" key={key} role="tab">
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          searchQuery.setQuery({ product: key as productCategoriesType });
                        }}
                        onKeyDown={() => {
                          searchQuery.setQuery({ product: key as productCategoriesType });
                        }}
                        className={`nav-link ${key === productTab && 'active'}`}
                      >
                        {capitalize(value)}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </section>

          <Categories product={productTab} currency={activeCurrency} />
        </div>
      </div>
    </div>
  );
};

export default ProductGroup;
