import React, { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import Icon from '+containers/Dashboard/Shared/Icons';
import { TIconNames } from '+containers/Dashboard/Shared/Icons/IconNames';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import { ScrollToTopSection } from '+containers/Dashboard/Shared/ScrollToTop';
import SecondaryDetails from '+containers/Dashboard/Shared/SecondaryDetails';
import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import { useFeedbackHandler, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import {
  capitalize,
  capitalizeRemovedash,
  eventType,
  findMismatchKeys,
  formatAmount,
  getDateAndTime,
  history,
  isAllowed,
  maskFirstCharacters,
  regions,
  statusIcon
} from '+utils';

import EventData from '../../Components/EventData';
import VerificationEventResult from '../../Components/VerificationEventResult';

import InfoSymbol from 'src/assets/img/dashboard/info.svg';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

export default function VerificationEventDetails() {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const { id } = useParams<{ id: string }>();
  const [showSensitive, setShowSensitive] = React.useState(false);
  const canViewSensitiveData = isAllowed(userAccess, ['identity_verification_details.update']);
  const {
    data: eventData,
    isLoading,
    refetch: refetchEventData
  } = useQuery(`VERIFICATION_${id.toUpperCase()}`, () => api.getVerification(id), {
    onError: (e: any) => {
      feedbackInit({
        message: "There has been an error getting this card's information",
        type: 'danger'
      });
    }
  });

  const { status, id_status: idStatus, metadata } = eventData || {};
  const mismatch = findMismatchKeys(eventData?.validation || {});
  const facialMatchingScore = eventData?.validation?.selfie?.confidence_rating;
  const facialMatchingMatch = eventData?.validation?.selfie?.match;
  const getMismatchMessage = (keys: string[]) => {
    const formattedKeys = keys?.map(key => <span style={{ color: '#915200' }}>{capitalizeRemovedash(key)}</span>);
    let mismatchMessage = [<span>Possible mismatch in </span>];
    if (formattedKeys.length > 1) {
      mismatchMessage.push(...formattedKeys.slice(0, -1).map((key, index) => <span key={index}>{key}, </span>));
      mismatchMessage.push(<span> and </span>);
      mismatchMessage.push(formattedKeys.slice(-1)[0]);
    } else if (formattedKeys.length === 1) {
      mismatchMessage.push(formattedKeys[0]);
    }
    return mismatchMessage;
  };

  const handleShowSensitive = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowSensitive(e.target.checked);
  };

  const isMismatch = (eventField: string) => {
    return mismatch.includes(eventField);
  };

  const checkedEventData = (eventData: string, eventField: string | string[]) => {
    let mismatched = false;
    if (Array.isArray(eventField)) {
      mismatched = eventField.some(field => isMismatch(field));
    }
    if (typeof eventField === 'string') {
      mismatched = isMismatch(eventField);
    }
    return (
      <div className="d-flex align-items-center">
        <span className="mr-2">{capitalizeRemovedash(eventData || '') || 'N/A'}</span>
        {mismatched && <Icon name="caution" width={12} height={12} />}
      </div>
    );
  };

  const getMatchingScore = (score: number) => {
    const isScoreLow = !facialMatchingMatch;
    return (
      <div className="d-flex align-items-center ">
        <span className={`d-flex align-items-center rounded mr-2 ivd__facial-match ${isScoreLow ? 'low' : ''}`}>{score}%</span>
        {isScoreLow && <Icon name="caution" width={12} height={12} />}
      </div>
    );
  };

  return (
    <div className="ivd">
      <div className="content-i">
        <div className="content-box">
          {isLoading ? (
            <div className="row">
              <div className="col-sm-12">
                <LoadingPlaceholder type="text" content={1} section={4} />
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-sm-12">
                  <button type="button" className="btn btn-link mb-2 identity__back-btn" onClick={() => history.goBack()}>
                    <i className="os-icon os-icon-arrow-left7" />
                    <span>Back to Identity</span>
                  </button>
                </div>
              </div>
              <div className="ivd__heading">
                <div className="ivd__heading-header">
                  <h2>
                    {id} <Copyable text={id} buttonClassName="identity-copy-btn" showText={false} />
                  </h2>
                  <span className={`identity__status ${status || 'undefined'}`}>{capitalizeRemovedash(status || '') || 'Not found'}</span>
                  <div className="ml-auto">
                    {canViewSensitiveData && (
                      <div className="custom-control custom-switch d-flex align-items-center ">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customSwitch1"
                          checked={showSensitive}
                          onChange={handleShowSensitive}
                        />
                        <label className="custom-control-label" htmlFor="customSwitch1">
                          Show sensitive detail
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="ivd__heading-subheader">
                  These are some details of this verification event. Some sensitive information may have been masked or hidden.
                </div>
              </div>
              {mismatch.length > 0 && (
                <div className="row mt-4 mb-4">
                  <div className="p-2 p-lg-3 g-3 g-lg-5 ivd__error-notification col-12 rounded-2">
                    <span className="icon-wrapper">
                      <Icon name="caution" width={15} height={15} />
                    </span>{' '}
                    {getMismatchMessage(mismatch)}
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-sm-12">
                  <SecondaryDetails
                    className="ivd__secondary-details"
                    title=""
                    data={[
                      {
                        Category: eventType[eventData?.type as keyof typeof eventType] || 'N/A',
                        'Date Created': getDateAndTime(eventData?.date_created) || 'N/A',
                        Region: regions[eventData?.country as keyof typeof regions] || 'N/A',
                        Merchant: checkedEventData(eventData?.merchant?.name, 'merchant_name'),
                        'Verification Class': capitalizeRemovedash(eventData?.class || '')?.replace('Id ', 'ID ') || 'N/A',
                        'Checks Done': capitalizeRemovedash(eventData?.identity_type_description || '') || 'N/A',
                        Name: checkedEventData(eventData?.full_name, ['full_name', 'last_name', 'first_name']),
                        'ID Number': maskFirstCharacters(eventData?.id, showSensitive ? 0 : 8) || 'N/A',
                        'Data Mactching': capitalize(eventData?.validation ? 'Yes' : 'No') || 'No',
                        ...(facialMatchingScore !== null && facialMatchingScore !== undefined
                          ? { 'Facial Matching Score': getMatchingScore(facialMatchingScore || 0) }
                          : {}),
                        Reference: <Copyable text={eventData?.reference} buttonClassName="identity-copy-btn" />,
                        'Billing Amount': `${formatAmount(eventData?.billing?.amount) || 0} ${eventData?.billing?.currency || 'USD'}`,
                        'Event Result': (
                          <span className={`identity__status ${eventData?.result || 'undefined'} no-bg font-auto`}>
                            {eventData?.result && (
                              <Icon
                                name={statusIcon?.[eventData?.result as keyof typeof statusIcon]?.icon as TIconNames}
                                width={12}
                                height={12}
                              />
                            )}{' '}
                            {capitalize(eventData?.result || 'Undefined')}
                            {(!eventData?.result || eventData?.result === 'invalid') && (
                              <ToolTip
                                type="info"
                                image={InfoSymbol}
                                message={eventData.result === 'invalid' ? getMismatchMessage(mismatch) : 'Identity could not be verified.'}
                              />
                            )}
                          </span>
                        )
                      }
                    ]}
                    leftSpacing="0"
                    hideLine
                  />
                </div>
              </div>
              {showSensitive && (
                <>
                  <VerificationEventResult idName={eventData?.identity_type_description} status={status} data={eventData} />

                  <EventData data={eventData} />
                </>
              )}
            </>
          )}
        </div>
        {showSensitive && <section className="back-to-top">{ScrollToTopSection()}</section>}
      </div>
    </div>
  );
}
