import React, { ElementRef, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import APIRequest from '+services/api-services';
import { useFeedbackHandler } from '+hooks';
import Ellipsis from '+assets/img/dashboard/ellipsis-blue.svg';
import UnlockPadlock from '+assets/img/dashboard/unlock-padlock.svg';
import Icon from '+containers/Dashboard/Shared/Icons';
import PermissionsTable from './PermissionsTable';
import '../../index.scss';

const api = new APIRequest();

export interface IProps {
  setModal: (value: string | Record<string, unknown>) => void;
  userId: string;
  isTwoFactor: boolean;
  isLocked: boolean;
  role: Record<string, string | { [id: string]: string }>;
}

const UnlockMerchantModal = ({ setModal, userId, isTwoFactor, isLocked, role }: IProps) => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const [manageModal, setManageModal] = useState(false);
  const [modalType, setModalType] = useState('');

  const toggleManageModal = () => {
    setManageModal((prevState) => !prevState);
  };

  const wrapperRef = useRef<ElementRef<'div'>>(null);

  const handleUnlockMerchant = useMutation((user_id: string) => api.unlockMerchant(user_id), {
    onSuccess: () => {
      queryClient.invalidateQueries(`TEAM_MEMBERS_${id}`);
    },
    onError: () => {
      feedbackInit({
        message: 'There has been an error unlocking the team members account.',
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const handleDisableTwoFactor = useMutation((user_id: string) => api.disableTwoFactor(user_id), {
    onSuccess: () => {
      queryClient.invalidateQueries(`TEAM_MEMBERS_${id}`);
    },
    onError: () => {
      feedbackInit({
        message: 'There has been an error disabling 2FA for the team members account.',
        type: 'danger',
        componentLevel: true
      });
    }
  });

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleClickOutside = (event: MouseEvent<HTMLElement>) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setManageModal(false);
    }
  };
  const handleDisplayModal = (type: string) => {
    setModalType(type);
  };
  const handleCloseModal = () => {
    setModalType('');
    setModal('');
  };

  useEffect(() => {
    if (!modalType) return;
    setModal(modalActions());
  }, [modalType]);

  const modalDetails = {
    unlock: {
      heading: 'Unlock Merchant?',
      description: (
        <div>
          <p>This account was locked due to many failed login attempts.</p>
          <p>
            Are you sure you want to <span className="unlock-bold">unlock</span> this merchant? This action cannot be undone
          </p>
        </div>
      ),
      secondButtonText: 'Yes, Unlock',
      completedHeading: 'Access Enabled!',
      completedDescription: "You have unlocked this merchant's account successfully",
      size: 'sm',
      secondaryCompletedModal: true,
      equalFooterBtn: true,
      headerBottomBorder: false,
      formCenter: true,
      secondButtonAction: async () => {
        return handleUnlockMerchant.mutateAsync(userId);
      }
    },
    disable: {
      heading: 'Disable Merchant 2FA',
      description: (
        <div>
          Are you sure you want to <strong>disable two-factor authentication (2FA)</strong> for this merchant? This action cannot be undone.
        </div>
      ),
      secondButtonText: 'Yes, Disable',
      completedHeading: '2FA Disabled',
      completedDescription: 'You have successfully disabled 2FA for this merchant.',
      size: 'sm',
      secondaryCompletedModal: true,
      equalFooterBtn: true,
      headerBottomBorder: false,
      formCenter: true,
      secondButtonAction: async () => {
        return handleDisableTwoFactor.mutateAsync(userId);
      }
    },
    view: {
      size: 'md',
      firstButtonText: 'Close',
      hideSecondButton: true,
      footerButtonWidthIsAuto: true,
      modalClassName: 'team-permission-modal',
      heading: 'View Permissions',
      description: 'View Merchant team member role designation and permissions.',
      content: (
        <div>
          <label htmlFor="role" style={{ fontWeight: 600 }}>
            Title Role
          </label>

          <div className="team-role-display team-display-role">
            <p>{role?.name}</p>
          </div>

          <p className="team-permission-modal-desc">
            Each permission has multiple actions represented as checkboxes. A disabled checkbox means that the action does not apply to that
            particular permission, or they cannot currently interact with that permission.
          </p>

          <div className="team-permission-modal-wrapper">
            <PermissionsTable role={role} />
          </div>
        </div>
      )
    }
  };

  const modalActions = () => {
    if (['unlock', 'disable', 'view'].includes(modalType)) return { close: handleCloseModal, ...modalDetails[modalType] };
    return {};
  };

  const onDisplayModal = (type: string) => {
    handleDisplayModal(type);
    toggleManageModal();
  };

  const items: Array<{
    onClick: () => void;
    hide: boolean;
    icon: ReactNode;
    title: string;
  }> = [
    {
      onClick: () => onDisplayModal('unlock'),
      hide: !isLocked,
      icon: <img src={UnlockPadlock} alt="unlock padlock" />,
      title: 'Unlock Merchant'
    },
    {
      onClick: () => onDisplayModal('disable'),
      hide: !isTwoFactor,
      icon: <Icon name="disable" />,
      title: 'Disable 2FA'
    },
    {
      onClick: () => onDisplayModal('view'),
      hide: false,
      icon: <Icon name="setting" />,
      title: 'View Permissions'
    }
  ];

  return (
    <span ref={wrapperRef} className="option-container">
      <span
        role="button"
        data-testid="ellipsis-button"
        onClick={toggleManageModal}
        onKeyDown={toggleManageModal}
        tabIndex={0}
        className="ellipsis"
      >
        <img src={Ellipsis} alt="ellipsis" />
      </span>
      {manageModal && (
        <ul className="option-list  element-box ellipsis__nav">
          {items
            .filter(({ hide }) => !hide)
            .map((item) => (
              <li
                role="button"
                onClick={item.onClick}
                tabIndex={0}
                onKeyDown={item.onClick}
                className="option-item ellipsis__item"
                key={item.title}
              >
                {item.icon}
                <span className="option-text">{item.title}</span>
              </li>
            ))}
        </ul>
      )}
    </span>
  );
};

export default UnlockMerchantModal;
