/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Calendar from 'react-calendar';
import { useWindowSize } from 'react-use';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { useReducerState } from '+hooks';
import { IAllDateTimeProps, IDateRange } from '+types';
import { logError } from '+utils';

import CustomTimeRange from './CustomTimeRange';
import Modal from './Modal';

import './SecondaryFilter.scss';
import 'react-calendar/dist/Calendar.css';

dayjs.extend(customParseFormat);

interface ICustomDateRangeProps {
  visible: boolean;
  close: () => void;
  selectedDate: IDateRange;
  setSelectedDateRange: (date: IDateRange & { type: string; allData: IAllDateTimeProps }) => void;
  showTimeRange?: boolean;
  showMaxEndDate?: boolean;
}

const formatDate = (date: string) => {
  if (!date) return dayjs().format('ddd, D MMM YYYY');
  return dayjs(dayjs(date)).format('ddd, D MMM YYYY');
};
// eslint-disable-next-line consistent-return
const formatDateTime = (date: string, time: string, meridiemStart: string | null, meridiemEnd: string | null) => {
  try {
    const meridiemStartLowerCase = meridiemStart?.toLowerCase();
    const meridiemEndLowerCase = meridiemEnd?.toLowerCase();
    if (!date && meridiemStart) {
      const todayDate = dayjs().format('ddd, D MMM YYYY');

      return `${dayjs(`${todayDate},${time}`).format('ddd, D MMM YYYY , hh:mm ')}${meridiemStartLowerCase}`;
    }
    if (!date && meridiemEnd) {
      const todayDate = dayjs().format('ddd, D MMM YYYY');

      return `${dayjs(`${todayDate},${time}`).format('ddd, D MMM YYYY , hh:mm ')}${meridiemEndLowerCase}`;
    }
    if (meridiemStart && meridiemEnd === null) {
      return `${dayjs(dayjs(`${date},${time}`)).format('ddd, D MMM YYYY , hh:mm ')}${meridiemStartLowerCase}`;
    }
    if (meridiemEnd && meridiemStart === null) {
      return `${dayjs(dayjs(`${date},${time}`)).format('ddd, D MMM YYYY , hh:mm ')}${meridiemEndLowerCase}`;
    }
  } catch (error) {
    logError(error);
  }
};

const CustomDateRangeCalendar = ({
  visible,
  close,
  selectedDate,
  setSelectedDateRange,
  showTimeRange = false,
  showMaxEndDate = true
}: ICustomDateRangeProps) => {
  const { width } = useWindowSize();
  const [state, setState] = useReducerState({
    startDate: selectedDate.startDate,
    endDate: selectedDate.endDate,
    startTime: '12:00',
    endTime: '12:00',
    meridiemStart: 'am',
    meridiemEnd: 'am'
  });

  const content = {
    heading: showTimeRange ? 'Date & Time Range' : 'Custom Date Range',
    description: showTimeRange
      ? 'Select the start period and end period to get targeted report.'
      : 'Select the start and end date to limit your search query.',
    secondButtonText: showTimeRange ? 'Set Date & Time' : 'Set Date',
    secondButtonDisable: !state.startDate || !state.endDate,
    content: (
      <form>
        <section className="secondary-datefilter--custom">
          <section>
            <label
              htmlFor="startDate"
              className="form-group filter-object filter-object-xxl flex-grow-1 w-auto --no-max-width --search-container"
            >
              <span>{showTimeRange ? 'From (Start Date & Time)' : 'From (Start Date)'}</span>
              <input
                title="startDate"
                aria-label="startDate"
                name="startDate"
                className="form-control"
                type="text"
                value={
                  showTimeRange ? formatDateTime(state.startDate, state.startTime, state.meridiemStart, null) : formatDate(state.startDate)
                }
              />
            </label>
            <div>
              <Calendar
                className="secondary-datefilter--custom-calendar"
                tileClassName="secondary-datefilter--custom-calendar-tile"
                next2Label={null}
                prev2Label={null}
                onChange={value => {
                  if (value) {
                    setState({ startDate: dayjs(new Date(String(value))).format('YYYY-MM-DD') });
                  }
                }}
                value={state.startDate}
                maxDate={new Date()}
              />
            </div>
          </section>
          <section>
            <label
              htmlFor="endDate"
              className="form-group filter-object filter-object-xxl flex-grow-1 w-auto --no-max-width --search-container"
            >
              <span>{showTimeRange ? 'To (End Date & Time)' : 'To (End Date)'}</span>
              <input
                title="endDate"
                aria-label="endDate"
                name="endDate"
                className="form-control"
                type="text"
                value={showTimeRange ? formatDateTime(state.endDate, state.endTime, null, state.meridiemEnd) : formatDate(state.endDate)}
              />
            </label>
            <div>
              <Calendar
                className="secondary-datefilter--custom-calendar"
                tileClassName="secondary-datefilter--custom-calendar-tile"
                next2Label={null}
                prev2Label={null}
                onChange={value => {
                  if (value) {
                    setState({ endDate: dayjs(new Date(String(value))).format('YYYY-MM-DD') });
                  }
                }}
                value={state.endDate}
                minDate={state.startDate ? new Date(state.startDate) : undefined}
                maxDate={showMaxEndDate ? new Date() : undefined}
              />
            </div>
          </section>
        </section>
        {showTimeRange ? (
          <section className="timer-section">
            <CustomTimeRange
              setSelectedTimeRange={timeData => {
                setState({
                  startTime: timeData.startTime,
                  endTime: timeData.endTime,
                  meridiemStart: timeData.meridiemStart,
                  meridiemEnd: timeData.meridiemEnd
                });
              }}
            />
          </section>
        ) : null}
      </form>
    ),
    secondButtonAction: async () => {
      close();
      setSelectedDateRange({
        startDate: state.startDate,
        endDate: state.endDate,
        type: `${formatDate(state.startDate)} - ${formatDate(state.endDate)}`,
        allData: state
      });
    },
    showCompletedModal: false
  };
  return <Modal visible={visible} close={close} {...content} size={width >= 1200 ? 'lg' : 'sm'} />;
};

export { CustomDateRangeCalendar, formatDate };
