import { createBrowserHistory } from 'history';

const _ = createBrowserHistory();

const history = {
  ..._,
  previousHistory: null as { path: string; type: unknown; data: unknown } | null,

  setPrevious(path: string, state?: unknown, info: { type?: unknown; data?: unknown } = {}) {
    history.previousHistory = { path, type: info.type, data: info.data };
  },

  getPathInfo(type: string) {
    if (type !== history.previousHistory?.type) return null;
    return history.previousHistory?.data;
  },

  push(path: string, state?: unknown, info: { type?: string; data?: unknown } = {}) {
    history.previousHistory = { path: window.location.pathname, type: info?.type, data: info?.data };
    return _.push(path, state);
  }
};

export default history;
