/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { CurrencyType, modalState, productConfigTableDataType } from '+types';
import {
  capitalize,
  capitalizeRemovedash,
  filteredOutObjectProperty,
  formatProductConfigStatus,
  getDate,
  getTime,
  history,
  isAllowed,
  queriesParams,
  switchCurrencyWithoutSymbol,
  switchStatus,
  switchTrxnMessage
} from '+utils';

import Table from '../../Shared/Table';
import RiskIndicator from '../components/RiskIndicator';
import MerchantCurrencyModal from './components/MerchantCurrencyModal';

import './index.scss';

const TabInfo = {
  access: 'Merchant with Access'
};

const api = new APIRequest();

const MerchantsTable = () => {
  const { currency } = useParams<{ currency: CurrencyType }>();
  const userAccess = useSetUserAccess();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const [activeModal, setActiveModal] = useState<modalState>(null);

  const activeTab = searchQuery.value.tab || 'access';
  const page = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '10';
  const keyword = searchQuery.value.keyword || undefined;
  const selectedConfig = searchQuery.value.selectedConfig || undefined;
  const status = searchQuery.value.status || undefined;

  const sortingParams = {
    limit,
    page,
    type: selectedConfig,
    merchant_name: keyword,
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value, [
      queriesParams.tab,
      queriesParams.page,
      queriesParams.limit,
      queriesParams.dateFrom,
      queriesParams.dateTo,
      queriesParams.status,
      queriesParams.keyword,
      queriesParams.selectedConfig
    ])
  };

  const { data, refetch, isLoading } = useQuery(
    [`${currency}_MERCHANTS`, limit, page, sortingParams],
    () => api.getMerchantList(currency, sortingParams),
    {
      refetchOnMount: 'always',
      onError: () => {
        feedbackInit({
          message: `There has been an error in getting this currency's merchants`,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      }
    }
  );
  const { data: configData, refetch: refetchConfig } = useQuery(
    [`${currency}_PRODUCT`, limit, page, sortingParams],
    () => api.getProductConfiguration(currency, {}),
    {
      refetchOnMount: 'always',
      onError: () => {
        feedbackInit({
          message: `There has been an error in getting this currency's config`,
          type: 'danger',
          action: {
            action: () => refetchConfig(),
            name: 'Try again'
          }
        });
      }
    }
  );

  const currencyStatus = configData?.data?.setting?.enabled;

  const merchantsCurrency = {
    className: '--merchant-currency-table',
    emptyStateHeading: 'No Merchants yet',
    data: data?.data || [],
    emptyStateMessage: 'There are no Merchants',
    rowURL: `/dashboard/product-config/${currency}`,
    rowKey: 'id',
    fields: (each: productConfigTableDataType) => ({
      data: {
        Merchant: <span id="merchant-name">{capitalize(each.name)}</span>,
        Email: <span id="merchant-email">{capitalize(each.email)}</span>,
        Risk_Level: <RiskIndicator riskLevel={each.risk_level} />,
        Currency_Status: (
          <>
            <span className={`status-pill smaller ${switchStatus(formatProductConfigStatus(each.status))}`} />
            <span>{capitalizeRemovedash(formatProductConfigStatus(each.status))}</span>
          </>
        ),
        Configuration_Type: <span className="lighter">{capitalize(each.configuration_type)}</span>,
        Date_Added: (
          <>
            <span>{getDate(each.created_at)}</span>
            <span className="annotation" style={{ marginLeft: '5px' }}>
              {getTime(each.created_at)}
            </span>
          </>
        )
      }
    })
  };

  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <button type="button" className="btn btn-link mb-2" onClick={() => history.goBack()}>
            <i className="os-icon os-icon-arrow-left7" />
            <span>Back to Product Config</span>
          </button>
        </div>
        <div className="content">
          <div className="first-section">
            <div className="title-wrapper">
              <span>
                <h4 className="title">{`${
                  switchCurrencyWithoutSymbol[currency as keyof typeof switchCurrencyWithoutSymbol]
                } Merchants [${currency}]`}</h4>
                {!isLoading && (
                  <span
                    className="status"
                    style={{
                      color: switchTrxnMessage[currencyStatus ? 'enabled' : 'disabled']?.color,
                      backgroundColor: switchTrxnMessage[currencyStatus ? 'enabled' : 'disabled']?.backgroundColor
                    }}
                  >
                    {switchTrxnMessage[currencyStatus ? 'enabled' : 'disabled']?.name}
                  </span>
                )}
              </span>
              <p className="subtitle">
                {`Here’s a comprehensive list of merchants who have access to ${currency} currency and products under this currency.`}
              </p>
            </div>
            {isAllowed(userAccess, ['transaction_config_details.update']) && (
              <div className="controls">
                <button
                  type="button"
                  className="btn btn-secondary --enable-btn"
                  onClick={() => setActiveModal(currencyStatus ? 'disable' : 'enable')}
                >
                  {`${currencyStatus ? 'Disable' : 'Enable'} ${currency} Access for a Merchant`}
                </button>

                <MerchantCurrencyModal
                  activeModal={activeModal}
                  setActiveModal={setActiveModal}
                  currency={currency}
                  status={currencyStatus ? 'active' : 'inactive'}
                  hideButton
                />
              </div>
            )}
          </div>
          <div className="table-section">
            <section className="os-tabs-w">
              <div className="os-tabs-controls os-tabs-complex">
                <ul className="nav nav-tabs" role="tablist">
                  {Object.entries(TabInfo).map(([tab, value]) => {
                    return (
                      <li
                        className="nav-item"
                        key={tab}
                        role="tab"
                        onClick={() => {
                          searchQuery.setQuery({ tab });
                        }}
                        onKeyDown={() => {
                          searchQuery.setQuery({ tab });
                        }}
                      >
                        <div className={`nav-link ${tab === activeTab && 'active'}`}>{capitalizeRemovedash(value)}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </section>
            <Table
              annotation="Merchant(s) with access"
              className={merchantsCurrency.className}
              data={merchantsCurrency?.data || []}
              tableHeadings={['Merchant', 'Email', 'Risk Level', 'Currency Status', 'Configuration Type', 'Date Added']}
              loading={isLoading}
              renderFields
              hasPagination
              rowKey={merchantsCurrency?.rowKey}
              rowURL={merchantsCurrency?.rowURL}
              pageSize={data?.paging?.page_size}
              totalItems={data?.paging?.total_items}
              current={parseInt(page, 10)}
              hideTable={merchantsCurrency?.data?.length === 0}
              filterKeywordPlaceholder="Search merchant..."
              tableWrapperClassName="table-responsive table-wrapper"
              emptyStateHeading={merchantsCurrency?.emptyStateHeading || ''}
              emptyStateMessage={merchantsCurrency.emptyStateMessage || ''}
              actionFn={currentPage => searchQuery.setQuery({ page: String(currentPage) })}
              limitAction={currentLimit => searchQuery.setQuery({ limit: String(currentLimit) })}
              filterHasAdvancedFilter={false}
              filterName={`merchants have access to ${currency} currency`}
              type="product-config"
              filterShowExport={false}
              isRowClickable={Boolean(isAllowed(userAccess, ['transaction_config_details.view']))}
              showDateFilter={false}
            >
              {merchantsCurrency.fields}
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantsTable;
