import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CurrenciesMenu from './CurrenciesMenu';
import MerchantCurrencyDetails from './MerchantCurrencyDetails';
import MerchantsTable from './MerchantsTable';
import ProductGroup from './ProductGroup';
import ProductDetails from './ProductGroup/ProductDetails';

export default function ProductConfig() {
  return (
    <Switch>
      <Route path="/dashboard/product-config" exact component={CurrenciesMenu} />
      <Route path="/dashboard/product-config/:currency" exact component={MerchantsTable} />
      <Route path="/dashboard/product-config/products/:currency" exact component={ProductGroup} />
      <Route path="/dashboard/product-config/products/:currency/:product/:method" exact component={ProductDetails} />
      <Route path="/dashboard/product-config/:currency/:merchant_id" exact component={MerchantCurrencyDetails} />
    </Switch>
  );
}
