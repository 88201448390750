/* eslint-disable import/prefer-default-export */
import CryptoJS from 'crypto-js';
import Filesaver from 'file-saver';
import { StateStorage } from 'zustand/middleware';

import { ValidationObject } from '+types/identity';

const key = `${process.env.REACT_APP_ZEUS_TRIDENT}`;

// API Downloading of files
export const APIDownload = (response: any, filename: string, format: string): void => {
  const blob = new Blob([response?.data], {
    type: response?.headers['content-type']
  });
  const downloadUrl = window.URL.createObjectURL(blob);
  return Filesaver.saveAs(downloadUrl, `${filename}.${format}`);
};

export const downloadFileViaUrl = async (url: string, fileName: string, contentType: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return Filesaver.saveAs(new Blob([blob], { type: contentType }), `${fileName}`);
};

export const defaultScrollToTop = () => document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });

export const encryptContent = (value: any | null) => {
  if (!value) return null;
  return CryptoJS.AES.encrypt(JSON.stringify(value), key).toString();
};

export const decryptContent = (value: any | null) => {
  if (!value) return null;
  const bytes = CryptoJS.AES.decrypt(value, key);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const isObjectNotEmpty = (obj: object | null | undefined) => {
  return ![null, undefined].includes(obj as null | undefined) && Object.keys(obj as object)?.length > 0;
};

export const areObjectsEqual = <T>(obj1: T, obj2: T): boolean => {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    return obj1.length === obj2.length && obj1.every((value, index) => areObjectsEqual(value, obj2[index]));
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  return (
    keys1.length === keys2.length &&
    keys1.every(k => {
      const value1 = obj1[k as keyof T];
      const value2 = obj2[k as keyof T];

      if (typeof value1 === 'object' && typeof value2 === 'object') return areObjectsEqual(value1, value2);

      return value1 === value2;
    })
  );
};

export const createParamsObject = (params: Record<string, any>) => {
  const allowedParams = ['page', 'limit', 'sortingParams', 'toExport', 'exportFormat', 'exportFields', 'id'];
  return allowedParams.map(param => (params[param] !== undefined ? params[param] : undefined));
};

export const maskFirstCharacters = (value: string, maskLength: number) => {
  const mask = '*'.repeat(maskLength);
  return `${mask}${value?.slice(maskLength)}`;
};

export const findMismatchKeys = (obj: ValidationObject, parentKey = ''): string[] => {
  let keys: string[] = [];
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      keys = [...keys, ...findMismatchKeys(obj[key] as ValidationObject, parentKey ? `${parentKey}.${key}` : key)];
    } else if (key === 'match' && obj[key] === false) {
      keys.push(parentKey);
    }
  }
  return keys;
};

export const isObjectAllTrueOrAllFalse = (obj: { [key: string]: boolean }, checkForTrue: boolean): boolean => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] !== checkForTrue) {
        return false;
      }
    }
  }
  return true;
};

export const StoreStorage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return (await decryptContent(sessionStorage.getItem(name))) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    await sessionStorage.setItem(name, encryptContent(value));
  },
  removeItem: async (name: string): Promise<void> => {
    await sessionStorage.removeItem(name);
  }
};
