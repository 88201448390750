import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import LoadingPlaceholder from '+dashboard/Shared/LoadingPlaceHolder';
import { useFeedbackHandler, useReducerState } from '+hooks';
import APIRequest from '+services/api-services';
import { availableProductType, CurrencyType, modalState, productCategoriesType } from '+types';
import { capitalizeRemovedash, formatProductConfigStatus, history, productMapping, switchTrxnMessage } from '+utils';

import MerchantCurrencyModal from '../../MerchantsTable/components/MerchantCurrencyModal';
import MerchantsAccessTable from '../components/MerchantsAccessTable';
import DefaultConfiguration from './DefaultConfiguration';

const api = new APIRequest();

const tabs = {
  default: 'Default Configuration',
  access: 'Merchants with Access'
} as const;

type tabKeyType = keyof typeof tabs;
type stateType = {
  activeTab: tabKeyType;
  activeModal: modalState;
};

const ProductDetails = () => {
  const { feedbackInit } = useFeedbackHandler();
  const { currency, product, method } = useParams<{
    currency: CurrencyType;
    product: productCategoriesType;
    method: availableProductType;
  }>();
  const [state, setState] = useReducerState<stateType>({
    activeTab: 'default',
    activeModal: ''
  });

  const params = {
    payment_method: method,
    payment_type: productMapping[product]
  };

  const {
    data: configData,
    refetch: refetchConfig,
    isLoading
  } = useQuery([`${currency}_PRODUCT_CONFIG`, currency, product], () => api.getProductConfiguration(currency, params), {
    refetchOnMount: 'always',
    onError: () => {
      feedbackInit({
        message: `There has been an error in getting this merchant's details`,
        type: 'danger',
        action: {
          action: () => refetchConfig(),
          name: 'Try again'
        }
      });
    }
  });

  const methodStatus = configData?.data?.setting?.enabled;
  return (
    <div className="content-i">
      <div className="content-box">
        <div className="row">
          <button type="button" className="btn btn-link mb-2" onClick={() => history.goBack()}>
            <i className="os-icon os-icon-arrow-left7" />
            <span>Back to Product Config</span>
          </button>
        </div>
        <div className="categories-container">
          <div className="categories-container__first" style={{ border: 'none', paddingTop: '1rem' }}>
            <div className="description">
              {!isLoading && (
                <span>
                  <h4>{`${capitalizeRemovedash(method)} [${currency}]`}</h4>
                  <span
                    className="status"
                    style={{
                      color: switchTrxnMessage[formatProductConfigStatus(String(methodStatus))]?.color,
                      backgroundColor: switchTrxnMessage[formatProductConfigStatus(String(methodStatus))]?.backgroundColor
                    }}
                  >
                    {switchTrxnMessage[formatProductConfigStatus(String(methodStatus))]?.name}
                  </span>
                </span>
              )}
              <p>
                Here, you’ll find the general configuration of this product and other product methods under it. You can make modifications
                here.
              </p>
            </div>
            <div className="controls method">
              <button
                type="button"
                className="btn btn-secondary --enable-btn"
                onClick={() => setState({ activeModal: methodStatus ? 'disable-channels' : 'enable-channels' })}
                title="more"
              >
                {`${methodStatus ? 'Disable' : 'Enable'} for all merchants`}
              </button>
              <MerchantCurrencyModal
                activeModal={state.activeModal}
                setActiveModal={() => setState({ activeModal: '' })}
                productType={productMapping[product]}
                currency={currency}
                method={method}
              />
            </div>
          </div>
        </div>
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex">
            <ul className="nav nav-tabs" role="tablist">
              {Object.entries(tabs).map(([key, value]) => {
                return (
                  <li className="nav-item" key={key} role="tab">
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setState({ activeTab: key as tabKeyType });
                      }}
                      onKeyDown={() => {
                        setState({ activeTab: key as tabKeyType });
                      }}
                      className={`nav-link ${key === state.activeTab && 'active'}`}
                    >
                      {capitalizeRemovedash(value)}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
        {isLoading ? (
          <LoadingPlaceholder type="text" background="#f5f6f6" content={2} section={3} />
        ) : (
          <>
            {state.activeTab === 'default' && <DefaultConfiguration />}
            {state.activeTab === 'access' && <MerchantsAccessTable />}
          </>
        )}
      </div>
    </div>
  );
};

export default ProductDetails;
