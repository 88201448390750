import APIRequest from '+services/api-services';
import { downloadFileViaUrl, history } from '+utils';

import useFeedbackHandler from './useFeedbackHandler';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

function useLargeExportDownloader(type?: string) {
  const { feedbackInit } = useFeedbackHandler();

  const getDownload = async () => {
    let exportLink = sessionStorage.getItem('EXPORT_LINK');
    if (exportLink) {
      const params = new URLSearchParams(exportLink);
      exportLink = params.get('exportToken');

      try {
        const res = await api.getFileDownload(exportLink ?? '');
        const { url, format } = res.data.data;

        feedbackInit({
          title: 'Download in progress',
          message: '- Your file is being prepared',
          type: 'warning'
        });

        // content Type expected as a string
        const contentType = {
          excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          csv: 'text/csv'
        }[format.toLowerCase()];

        await downloadFileViaUrl(url, exportLink ?? '', contentType);
        sessionStorage.removeItem('EXPORT_LINK');
        feedbackInit({
          title: 'Export Successful',
          message: `- Successfully downloaded your ${type}`,
          type: 'success'
        });
      } catch (error) {
        sessionStorage.removeItem('EXPORT_LINK');
        history.push('/expired-export');
      }
    }
  };

  return { getDownload };
}

export default useLargeExportDownloader;
