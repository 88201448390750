import { logError, APIDownload } from '+utils';
import useFeedbackHandler from './useFeedbackHandler';

const parameterizeArray = (key: string, arr: any[]) => {
  const urlArr = arr.map(encodeURIComponent);
  return urlArr.join(`&${key}[]=`);
};

const useExport = (
  action: (format: string, fields?: string) => Promise<any>,
  fileFormat: string,
  fileName: string,
  exportDescription: string,
  close: () => void,
  fieldsToExport: string[],
  largeExportAction: () => void
) => {
  const { feedbackInit } = useFeedbackHandler();
  const fields = parameterizeArray('fieldsToExport', fieldsToExport);

  const getExport = async () => {
    try {
      const res = await action(fileFormat, fields);
      if (res.status === 202) {
        largeExportAction();
      } else {
        APIDownload(res, fileName, fileFormat);
        feedbackInit({
          title: 'Export Successful',
          message: exportDescription,
          type: 'success'
        });
        close();
      }
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error exporting your selected file`,
        type: 'danger',
        componentLevel: true
      });
      throw error;
    }
  };

  return getExport;
};

export default useExport;
