import Bugsnag from '@bugsnag/js';
import { Nullable } from 'src/types';

export const logError = (error: any): any => {
  if (error.response) return Bugsnag.notify(error);
  return Bugsnag.notify(new Error(error));
};

export const EmailValidation = (value: string, validateIsKoraEmail = false): Nullable<string> => {
  let errorMessage;
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(value) && !validateIsKoraEmail) {
    errorMessage = 'Invalid email address';
  }
  if (validateIsKoraEmail && !/^[A-Z0-9._%+-]+@korapay.com$/i.test(value)) {
    errorMessage = 'Must be Kora email address';
  }
  if (!value) {
    errorMessage = 'Your email is required';
  }
  return errorMessage;
};

export const isAllowed = (userPermissions: Record<string, boolean> | null, permissions: string[]): boolean | string => {
  if (!userPermissions || !permissions) return false;
  return permissions.some((permission: string) => {
    const value = userPermissions[permission];
    return value;
  });
};

export const isNullish = <T>(value: T) => {
  return value === null || value === undefined;
};

export const removeSpecialChar = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (!/^[0-9a-zA-Z\s]*$/.test(e.key)) {
    e.preventDefault();
  }
};
