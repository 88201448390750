import React from 'react';
import { useQuery } from 'react-query';

import LoadingPlaceholder from '+dashboard/Shared/LoadingPlaceHolder';
import { useFeedbackHandler, useReducerState, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import { CurrencyType, modalState, productCategoriesType } from '+types';
import { capitalize, capitalizeRemovedash, formatProductConfigStatus, productMapping, switchTrxnMessage } from '+utils';

import MerchantCurrencyModal from '../../MerchantsTable/components/MerchantCurrencyModal';
import MerchantsAccessTable from '../components/MerchantsAccessTable';
import ProductCards from '../components/ProductCards';

import './index.scss';

const tabs = {
  default: 'Default Configuration',
  access: 'Merchants with Access'
} as const;

type TabKeyType = keyof typeof tabs;

const api = new APIRequest();
const Categories = ({ product, currency }: { product: productCategoriesType; currency: CurrencyType }) => {
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery<{ tab: TabKeyType }>();
  const activeTab = searchQuery.value.tab ?? 'default';
  const [state, setState] = useReducerState<{ activeModal: modalState }>({
    activeModal: null
  });

  const params = {
    payment_type: productMapping[product]
  };

  const {
    data: configData,
    refetch: refetchConfig,
    isLoading
  } = useQuery([`${currency}_PRODUCTS`, currency, product], () => api.getProductConfiguration(currency, params), {
    onError: () => {
      feedbackInit({
        message: `There has been an error in getting this merchant's details`,
        type: 'danger',
        action: {
          action: () => refetchConfig(),
          name: 'Try again'
        }
      });
    }
  });

  const productStatus = configData?.data?.setting?.enabled;
  return isLoading ? (
    <LoadingPlaceholder type="text" background="#f5f6f6" content={2} section={3} />
  ) : (
    <div className="categories-container">
      <div className="categories-container__first no-bottom-border">
        <div className="description">
          <span>
            <h4>{capitalize(product)}</h4>
            <span
              className="status"
              style={{
                color: switchTrxnMessage[formatProductConfigStatus(String(productStatus))]?.color,
                backgroundColor: switchTrxnMessage[formatProductConfigStatus(String(productStatus))]?.backgroundColor
              }}
            >
              {switchTrxnMessage[formatProductConfigStatus(String(productStatus))]?.name}
            </span>
          </span>
          <p>{`Here you can find the ${capitalize(
            product
          )?.toLowerCase()} products for this currency. You can modify the limits and payment channels configuration.`}</p>
        </div>
        <div className="controls">
          <button
            type="button"
            className="btn btn-secondary --enable-btn"
            onClick={() =>
              setState({
                activeModal: productStatus ? 'disable-product-all' : 'enable-product-all'
              })
            }
            title={`${productStatus ? 'Disable' : 'Enable'} ${product} for all merchant`}
          >
            {`${productStatus ? 'Disable' : 'Enable'} ${product} for all merchant`}
          </button>
          <MerchantCurrencyModal
            activeModal={state.activeModal}
            setActiveModal={() => setState({ activeModal: '' })}
            productType={productMapping[product]}
            currency={currency}
          />
        </div>
      </div>
      <section className="os-tabs-w">
        <div className="os-tabs-controls os-tabs-complex">
          <ul className="nav nav-tabs" role="tablist">
            {Object.entries(tabs).map(([key, value]) => {
              return (
                <li className="nav-item" key={key} role="tab">
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      searchQuery.setQuery({ tab: key as TabKeyType });
                    }}
                    onKeyDown={() => {
                      searchQuery.setQuery({ tab: key as TabKeyType });
                    }}
                    className={`nav-link ${key === activeTab && 'active'}`}
                  >
                    {capitalizeRemovedash(value)}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
      {activeTab === 'default' && configData && Object.entries(configData?.data?.setting).length > 0 && !isLoading && (
        <ProductCards currency={currency} config={configData?.data?.setting} product={product} />
      )}
      {activeTab === 'access' && <MerchantsAccessTable />}
    </div>
  );
};

export default Categories;
